import React, { Component } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import Headers from '../Headers_NAR.js';
// bootstrapt
import { Form, Container, Row, Col, Button } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
// Firebase
import firebase from '../config/database_AR.js';
import 'firebase/compat/firestore';
// 取得 Firebase 中的 Personal 資料表
const ref = firebase.firestore();

class EditWatch extends Component {

  constructor(props){
    super(props)
    this.state = {
      Watchsid:"",
      watchid:"",
      account:"",
      password:"",
      useraccesstoken:"",
      useraccesstokensecret:"",
    }
  }
  async getWatchById(firebaseid){

    ref.collection('Watch').doc(firebaseid).get().then(items => {
      const data = items.data();
      const watchidvalue = data.watchid;
      const accountvalue = data.account;
      const passwordvalue = data.password;
      const useraccesstokenvalue = data.userAccessToken;
      const useraccesstokensecretvalue = data.userAccessTokenSecret;
      this.setState({ Watchsid: firebaseid, watchid: watchidvalue, account: accountvalue, password: passwordvalue, useraccesstoken: useraccesstokenvalue, useraccesstokensecret: useraccesstokensecretvalue });
    });

    
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChangeAccount(e){
    this.setState({ account: e.target.value })
    // this.handleSubmit = this.handleSubmit.bind(this);
  }
  handleChangePassword(e){
    this.setState({password:e.target.value})
  }
  handleChangeUserAccessToken(e){
    this.setState({useraccesstoken:e.target.value})
  }
  handleChangeUserAccessTokenSecret(e){
    this.setState({useraccesstokensecret:e.target.value})
  }
  handleSubmit(e) {
    const today = new Date();
    const date = today.getFullYear()+'/'+(today.getMonth()+1)+'/'+today.getDate()+'     '+today.getHours()+':'+today.getMinutes()+':'+today.getSeconds()+' ';
    ref.collection('Watch').doc(this.state.Watchsid).update({
      "account":this.state.account,
      "password":this.state.password,
      "userAccessToken":this.state.useraccesstoken,
      "userAccessTokenSecret":this.state.useraccesstokensecret,
      "editedTime": date
    });
    console.log('修改成功')
  }

  componentDidMount(){
    const { firebaseid } = this.props.match.params;
      this.getWatchById(firebaseid)
  }

  

  render() {
    // console.log(this.state.selectShiftOptions)
    const h2={
      fontSize: '2rem',
      fontWeight: 'bold',
      paddingTop: '10px'
    }
    const h4={
      fontSize: '1.5rem',
      fontWeight: 'bold',
    }
    const p={
      fontSize: '1.5rem',
      color: '#50a9b9'
    }
    const line={
      height: '1px',
      borderTop: 'solid gray 1px',
      margin: '10px 0'
    }
    const margin={
      margin: '10px',
    }
    const button={
      margin: '5px',
      float:'right'
    }
    return(
      <div>
        <Headers/>
        <Container>
          <h2 style={h2}>修改手錶資料</h2>
          <Container style={line}></Container>
          <Form style={margin}>
            <p style={h4}>手錶編號 ： <span style={p}>{this.state.watchid}</span></p>
            <Row className="g-2">
              <Col md>
                <p style={h4}>帳號：</p>
                <Form.Control
                  placeholder="請輸入帳號"
                  value={this.state.account}
                  onChange={this.handleChangeAccount.bind(this)}
                />
                <p style={h4}>密碼：</p>
                <Form.Control
                  placeholder="請輸入密碼"
                  value={this.state.password}
                  onChange={this.handleChangePassword.bind(this)}
                />
              </Col>
              <Col md>
                <p style={h4}>User Access Token：</p>
                <Form.Control
                  placeholder="請輸入 UserAccessToken"
                  value={this.state.useraccesstoken} 
                  onChange={this.handleChangeUserAccessToken.bind(this)}
                />
                <p style={h4}>User Access Token Secret：</p>
                <Form.Control
                  placeholder="請輸入 UserAccessTokenSecret"
                  value={this.state.useraccesstokensecret} 
                  onChange={this.handleChangeUserAccessTokenSecret.bind(this)}
                />
              </Col>
            </Row>
            <br />
            <br />
            <Form style={h4}>
              <h2 style={h2}>請確認以下資料：</h2>
              <Container style={line}></Container>
              <p style={h4}>手錶編號 ： <span style={p}>{this.state.watchid}</span></p>
              <Row >
                <Col>
                  帳號  ： <span style={p}>{this.state.account}</span><br />
                  密碼  ： <span style={p}>{this.state.password}</span><br/>
                </Col>
                <Col xs={7}>
                  &emsp;&emsp;&emsp; UAT  ： <span style={p}>{this.state.useraccesstoken}</span><br/>
                  UAT Secret  ： <span style={p}>{this.state.useraccesstokensecret}</span><br/>
                </Col>
              </Row>
            </Form><br />
            <Link to="/nar/watch"><Button onClick={this.handleSubmit} variant="outline-success" style={button} size="lg">確認修改</Button></Link>
          </Form>
        </Container>
      </div>
    );
  }
  
}
export default EditWatch;
