import {BrowserRouter,Switch,Route} from 'react-router-dom';
import Signin_N from "./pages_N/Signin_N.js";
// 月更新資料
import MonthList_N from "./pages_N/MonthList_N.js";
import AddMonth_N from "./pages_N/AddMonth_N.js";
import EditMonth_N from "./pages_N/EditMonth_N.js";
// 基本資料
// 護理長
import PersonalDeveloper_N from './pages_N/PersonalDeveloper_N.js';
import AddPersonalDeveloper_N from './pages_N/AddPersonalDeveloper_N.js';
import EditPersonalDeveloper_N from './pages_N/EditPersonalDeveloper_N.js';
// 護理師
import Personal_N from './pages_N/Personal_N.js';
import AddPersonal_N from './pages_N/AddPersonal_N.js';
import EditPersonal_N from './pages_N/EditPersonal_N.js';
// 手錶資料
import WatchList_N from './pages_N/WatchList_N.js';
import AddWatch_N from "./pages_N/AddWatch_N.js";
import EditWatch_N from "./pages_N/EditWatch_N.js";
// 數據分析
import Analyze_N from './pages_N/Analyze_N.js';
import AnalyzeHeartRate_N from './pages_N/Analyze_HeartRate_N.js';
import AnalyzeSleep_N from './pages_N/Analyze_Sleep_N.js';
import AnalyzeStress_N from './pages_N/Analyze_Stress_N.js';
import Picture_N from './pages_N/Picture_N.js';
import PictureBox_N from './pages_N/Picture_Box_N.js';

function App_N() {
  return (
    <BrowserRouter>
      <Switch>        
        {/* 登入 */}
        <Route path='/n' exact>
          <Signin_N/>
        </Route>
        {/* 月更新資料 */}
        <Route path='/n/month' exact>
          <MonthList_N/>
        </Route>
        <Route path='/n/addmonth' exact>
          <AddMonth_N/>
        </Route>
        <Route exact path='/n/editmonth/:firebaseid' component={EditMonth_N} ></Route>
        {/* 基本資料 */}
        {/* 護理長看到畫面 */}
        <Route path='/n/personalDeveloper' exact>
          <PersonalDeveloper_N/>
        </Route>
        <Route path='/n/addpersonalDeveloper' exact>
          <AddPersonalDeveloper_N/>
        </Route>
        <Route path='/n/editpersonalDeveloper/:account' component={EditPersonalDeveloper_N} exact></Route>
        {/* 護理師看到畫面 */}
        <Route path='/n/personal/:account' component={Personal_N} exact></Route>
        <Route path='/n/addpersonal/:account' component={AddPersonal_N} exact></Route>
        <Route path='/n/editpersonal/:account' component={EditPersonal_N} exact></Route>
        {/* 數據分析 */}
        <Route path ='/n/analyze' exact>
          <Analyze_N/>
        </Route>
        <Route path ='/n/Picture' exact>
          <Picture_N/>
        </Route>
        {/* 心率指數 */}
        <Route path='/n/analyze/heartrate' exact>
          <AnalyzeHeartRate_N />
        </Route>
        {/* 睡眠狀態 */}
        <Route path='/n/analyze/sleep' exact>
          <AnalyzeSleep_N />
        </Route>
        {/* 壓力指數 */}
        <Route path='/n/analyze/stress' exact>
          <AnalyzeStress_N />
        </Route>
        <Route path='/n/Picture/Box' exact>
          <PictureBox_N />
        </Route>
                {/* 手錶資料 */}
        <Route path='/n/watch' exact>
          <WatchList_N/>
        </Route>
        <Route path='/n/addwatch' exact>
          <AddWatch_N/>
        </Route>
        <Route path='/n/editwatch/:firebaseid' component={EditWatch_N} exact></Route>
        
      </Switch>
    </BrowserRouter>
  );
}

export default App_N;
