import React, { Component } from 'react';
// bootstrap
import { Form, Button, Container, Navbar, Nav ,Dropdown } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import {Link} from 'react-router-dom';

// 連接 Firebase
import firebase from '../config/database.js';
import 'firebase/compat/auth';


class Signin extends Component{
    constructor(props){
        super(props)
        this.state = {
            account : '',
            password :''
        }
    }

    handleChangeAccount(e){
        this.setState({account:e.target.value})
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleChangePassword(e){
        this.setState({password:e.target.value})
    } 
    handlechangeNER()
    {
        window.location.replace("/ner")
    }
    handlechangeNAR()
    {
        window.location.replace("/nar")
    }
    handlechange()
    {
        window.location.replace("/")
    }
    handleSubmit() {
        firebase
        .auth()
        .signInWithEmailAndPassword(this.state.account, this.state.password)
            .then(() => {
            // 判斷如果是護理長或後端管理者登入的帳號 overworkdeveloper@gmail.com，則畫面跳轉到路徑 /n/personalDeveloper 檔案
            if (this.state.account === 'overworkdeveloper@gmail.com') {
                // console.log('登入');
                window.location.replace("/n/personalDeveloper");
            }
            // 如果是護理師登入的帳號，則畫面跳轉到路徑 /n/personal/(每位護理師不同的帳號)
            else {
                const uri = "/n/personal/" + encodeURIComponent(this.state.account);
                // alert(uri);
                window.location.replace(uri);
            }
        })
        // 如果輸入的帳號及密碼沒有在 Firebase 的 Authentication 中時，跳出錯誤訊息
        .catch((error)=>{
            alert('帳號密碼錯誤!!');
        });
    }


    render() {
        const h4={
            fontSize: '1.5rem',
            fontWeight: 'bold',
        }
        const h4a={
            fontSize: '1.5rem',
        }
        const h1={
            fontSize: '3rem',
        }
        const buttom={
            margin:'10px',
            fontSize: '20px',
            color: 'green',
            fontWeight: 'bold',
            backgroundColor:'white',
            borderColor:'black',
        }
        const buttom_change={
            margin:'10px',
            fontSize: '20px',
            color: 'black',
            fontWeight: 'bold',
            backgroundColor:'white',
            borderColor:'black',
            attached:'right'
           
        }
        const li={
            float: 'left',
            display: 'block',
            color: 'white',
            textAlign: 'center',
            padding: '14px 16px',
            textDecoration: 'none',
            fontSize: '25px',
        }
        return (
            <div>
                <Navbar bg="dark" >
                    <Navbar.Brand style={li}>代理伺服器_外科部</Navbar.Brand>
                    <Nav.Link style={li} onClick={this.handlechangeNER}>代理_急診室</Nav.Link>
                    <Nav.Link style={li} onClick={this.handlechangeNAR}>代理_安寧病房</Nav.Link>
                    <Nav.Link style={li} onClick={this.handlechange}>切換模式</Nav.Link>
                </Navbar>
                <Container>
                    
                    <Form>
                        <Form.Group>
                            <br/>
                            <Form.Label style={h4}>帳號</Form.Label>
                            <Form.Control 
                                type="text"
                                //defaultValue={this.state.account}
                                value={this.state.account} 
                                onChange={this.handleChangeAccount.bind(this)} 
                                placeholder="請輸入帳號" 
                                style={h4a} 
                            />
                        </Form.Group>
                        <br/>
                        <Form.Group>
                            <Form.Label style={h4}>密碼</Form.Label>
                            <Form.Control 
                                type="password" 
                                value={this.state.password} 
                                onChange={this.handleChangePassword.bind(this)} 
                                placeholder="請輸入密碼" 
                                style={h4a}
                            />
                        </Form.Group>
                        <Button style={buttom} onClick={this.handleSubmit}>登入</Button>
                    </Form>
                </Container>
            </div>
        );
    }
}

export default Signin;
