// 數據分析 - 壓力指數
import React, { PureComponent } from 'react';
import Select from 'react-select';
import { Link } from 'react-router-dom';
import Headers from '../Headers_NAR.js';
import axios from "axios";
// bootstrap
import { Table, Container, Row, Col } from 'react-bootstrap';
// import { Image } from 'semantic-ui-react'
import A from '../Picture/A.png';
import B from '../Picture/B.png';





export default class PictureBox_AR extends PureComponent {
  
  constructor(props){
    super(props)
    this.state = ({Picture : ''})
    this.changePicture = this.changePicture.bind(this)
  }
  changePicture(event){
 
    this.setState({Picture:event.target.value})
  }
  componentDidUpdate(){
    console.log(`已將state.Picture變動為：${this.state.Picture}`)
  }
 
  render() {
    const h2={
        fontSize: '2rem',
        fontWeight: 'bold',
        paddingTop: '10px'
    }
    const h4={
        fontSize: '2rem',
        fontWeight: 'bold',
      marginTop: '30px',
        marginBottom: '20px'
    }
    const h3={
        fontSize: '1.5rem',
      fontWeight: 'bold',
        marginTop: '20px',
        marginBottom: '10px'
    }
    const line={
      height: '1px',
      borderTop: 'solid gray 1px',
      margin: '10px 0'
    }
    const textCenter={
      textAlign: 'center',
    }
    const h5={
      fontSize: '1.25rem',
    }
    let text
    if(this.state.gender === "Age")
          text=< img src={A} alt="A"/>
    else if(this.state.gender === "Banbie")
      text = ''
    else if(this.state.gender === "Salary")
      text = ''
    else
      text = ""

    return (
      <div>
        <Container>
          <br />
          
          <Row>
            <Col md>
            <select onChange={this.changePicture.bind(this)} class="form-control" style={h5}>
            <option value="">請選擇...</option>
                <option value="age">年齡</option>
                <option value="Banbie">班別</option>
                <option value="Salary">薪資</option>
              </select>
            </Col>
            <Col md>
            <Select option value="班別">班別</Select>
            </Col>
          </Row>
          <h2 style={h2}>圖片展示</h2>
          <Container style={line}></Container>
          {text}
          <img src={A} alt="A"/>
          <img src={B} alt="B"/>
         
          
          
        </Container>
      </div>
    );
  }
}
