// 數據分析 - 睡眠狀態
import React, { PureComponent } from 'react';
import Select from 'react-select';
import { Link } from 'react-router-dom';
import Headers from '../Headers_NER.js';
import axios from "axios";
// bootstrap
import { Table, Container, Row, Col } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
// 數線圖
import { AreaChart, Area, BarChart, Bar, Cell, Legend, LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, LabelList, } from 'recharts';
// 連接 Firebase
import firebase from '../config/database_ER.js';
import 'firebase/compat/firestore';
const ref = firebase.firestore();

export default class SleepsAnalyze extends PureComponent {
  constructor(props){
    super(props)
    this.state = {
      selectWatchOptions : [],
      watch_UAT: '',   // 手錶 UserAccessToken
      watch_id: '',   // 手錶編號 GarminWatch01
      SleepData:[],   //完整的睡眠資料
      selectCalendarOptions: [], //此陣列存放日期
      calendar_id: '',
      calendar_Date: '',
    }
  }

  //! 取得 watch 資料
  async getWatchOptions(){
    const watchData = [];
    const watchId = [];
    // 使用 get() 的方式，一次性讀取資料
    ref.collection('Watch').orderBy('account').get().then(items => {
      items.forEach(doc => {
        watchId.push(doc.id);
        watchData.push(doc.data());
      });
      for (let i = 0; i < watchData.length; i++){
        watchData[i].firebaseid = watchId[i];
        const watchoptions = watchData.map(Watch => ({
          "value": Watch.userAccessToken,
          "label": Watch.watchid
        }));
        this.setState({selectWatchOptions: watchoptions});
      }
    });
  }

  //! 取得日期
  async getCalendarOptions(uat) {
    const response = await axios.get('https://imow.nutc.edu.tw:8009/sleepscalendar/'+ uat );   // 到 sleepscalendar 地址抓取 日期 陣列
    // const response = await axios.get('https://localhost:8080/sleepscalendar/'+ uat );   // 到 sleepscalendar 地址抓取 日期 陣列
    const calendarArray = response.data;  // calendarArray 存放傳回來的 日期陣列
    // 將 日期陣列 中的資料放到 calendaroptions 中
    const calendaroptions = calendarArray.map(Calendar => ({
      "value" : Calendar.id,
      "label": Calendar.calendar,
    }));
    console.log(calendaroptions);
    this.setState({selectCalendarOptions: calendaroptions});
  }

  //! 取得 Sleeps 的所有資料，uat = userAccessToken
  async getSleepsOptions(uat, watchid , calendar) {
    console.log(uat, watchid, calendar);
    try{
      // const response = await axios.post('https://localhost:8080/sleepsanalyze',{uat, watchid, calendar});
      const response = await axios.post('https://imow.nutc.edu.tw:8009/sleepsanalyze',{uat, watchid, calendar});
      if (response.data == '') {
        console.log(response.data);
        this.setState({ SleepData: response.data }); //將內部陣列存入外層陣列
        alert('無資料');
      } else {
        console.log(response.data);
        this.setState({SleepData: response.data}); //將內部陣列存入外層陣列
      }
    }catch(error){
      console.log(error);
    }
  }

  // 改變 this.state.watch 的資料
  handleChangeWatch(e) {
    this.setState({ watch_UAT: e.value, watch_id: e.label });
    // 將使用者選擇的 userAccessToken 傳給 getCalendarOptions
    this.getCalendarOptions(e.value);
    
  }

  // 改變 this.state.calendar 的資料
  handleChangeCalendar(e){
    this.setState({ calendar_id: e.value, calendar_Date: e.label });
    // 將 userAccessToken 跟 calendar (使用者選擇的日期) 傳給 getSleepsOptions
    this.getSleepsOptions(this.state.watch_UAT, this.state.watch_id, e.label);
  }

  componentDidMount(){
    this.getWatchOptions();
  }

  render() {
    const h2={
        fontSize: '2rem',
        fontWeight: 'bold',
        paddingTop: '10px'
    }
    const h4={
        fontSize: '2rem',
        fontWeight: 'bold',
      marginTop: '30px',
        marginBottom: '20px'
    }
    const h3={
        fontSize: '1.5rem',
      fontWeight: 'bold',
        marginTop: '20px',
        marginBottom: '10px'
    }
    const line={
      height: '1px',
      borderTop: 'solid gray 1px',
      margin: '10px 0'
    }
    const textCenter={
      textAlign: 'center',
    }
    return (
      <div>
        <Container>
          <Row className="g-2">
            <Col md>
              <h3 style={h3}>手錶編號：</h3>
              <Select options={this.state.selectWatchOptions} onChange={this.handleChangeWatch.bind(this)} /><br />
            </Col>
            <Col md>
              <h3 style={h3}>選擇日期：</h3>
              <Select options={this.state.selectCalendarOptions} onChange={this.handleChangeCalendar.bind(this)} /><br />
            </Col>
          </Row>
          <h2 style={h2}>{this.state.calendar_Date} 睡眠狀態（秒）</h2>
          {/* 數線圖 */}
          <Container style={line}></Container>
          <ResponsiveContainer width='100%' height={200}>
            <AreaChart
              width={500}
              height={300}
              data={this.state.SleepData}
              margin={{
                top: 20,
                right: 5,
                left: 0,
                bottom: 5,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis type="category" dataKey="Time"/>
              <YAxis />
              <Tooltip />
              <Area dataKey="id" fill="#82ca9d" minPointSize={5}>
                {/* <LabelList dataKey="sleepType" label={{ position: 'top' }}  /> */}
              </Area>
            </AreaChart>
          </ResponsiveContainer>
          <br />
          <div style={textCenter}>
            <span style={{ color: 'red' }}>1：深層睡眠</span>&emsp;
            <span style={{ color: '#dea54b' }}>2：淺層睡眠</span>&emsp;
            <span style={{ color: '#91c4f2' }}>3：REM 快速動眼期</span>&emsp;
            <span style={{ color: '#72bda3' }}>4：清醒</span>&emsp;
          </div>
          <h4 style={h4}>資料顯示</h4>
          {/* 表格 */}
          <Table striped bordered hover style={textCenter}>
            <thead>
              <tr>
                <th>NO</th>
                <th>User</th>
                <th>Calendar</th>
                <th>Time</th>
                <th>ID</th>
                <th>SleepType</th>
              </tr>
            </thead>
            <tbody>
              {this.state.SleepData.map((Sleep, index) => {
                if (Sleep.sleepType == '清醒') {
                  return(
                    <tr>
                      <td>{index + 1}</td>
                      <td>{Sleep.Watch_id}</td>
                      <td>{Sleep.Calendar}</td>
                      <td>{Sleep.Time}</td>
                      <td>{Sleep.id}</td>
                      <td style={{ color: '#72bda3' }}>{Sleep.sleepType}</td>
                    </tr>
                  );
                }
                else if (Sleep.sleepType == 'REM 快速動眼期') {
                  return(
                    <tr>
                      <td>{index + 1}</td>
                      <td>{Sleep.Watch_id}</td>
                      <td>{Sleep.Calendar}</td>
                      <td>{Sleep.Time}</td>
                      <td>{Sleep.id}</td>
                      <td style={{ color: '#91c4f2' }}>{Sleep.sleepType}</td>
                    </tr>
                  );
                }
                else if (Sleep.sleepType == '淺層睡眠') {
                  return(
                    <tr>
                      <td>{index + 1}</td>
                      <td>{Sleep.Watch_id}</td>
                      <td>{Sleep.Calendar}</td>
                      <td>{Sleep.Time}</td>
                      <td>{Sleep.id}</td>
                      <td style={{ color: '#dea54b' }}>{Sleep.sleepType}</td>
                    </tr>
                  );
                }
                else if (Sleep.sleepType == '深層睡眠') {
                  return(
                    <tr>
                      <td>{index + 1}</td>
                      <td>{Sleep.Watch_id}</td>
                      <td>{Sleep.Calendar}</td>
                      <td>{Sleep.Time}</td>
                      <td>{Sleep.id}</td>
                      <td style={{ color: 'red' }}>{Sleep.sleepType}</td>
                    </tr>
                  );
                }
              })}   
            </tbody>
          </Table>
        </Container>
      </div>
    );
  }
}
