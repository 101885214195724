// 數據分析 - 壓力指數
import React, { PureComponent } from 'react';
import Select from 'react-select';
import { Link } from 'react-router-dom';
import Headers from '../Headers_NER.js';
import axios from "axios";
// bootstrap
import { Table, Container, Row, Col } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
// 數線圖
import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, LabelList, } from 'recharts';
// 連接 Firebase
import firebase from '../config/database_ER.js';
import 'firebase/compat/firestore';
const ref = firebase.firestore();

export default class StressAnalyze extends PureComponent {
  constructor(props){
    super(props)
    this.state = {
      selectWatchOptions : [],
      watch_UAT: '',
      watch_id: '',
      StressData:[],   //完整的 壓力 資料
      selectCalendarOptions: [], //此陣列存放日期
      calendar_id: '',
      calendar_Date: '',
    }
  }

  // ! 取得 watch 資料
  async getWatchOptions(){
    const watchData = [];
    const watchId = [];
    // 使用 get() 的方式，一次性讀取資料
    ref.collection('Watch').orderBy('account').get().then(items => {
      items.forEach(doc => {
        watchId.push(doc.id);
        watchData.push(doc.data());
      });
      for (let i = 0; i < watchData.length; i++){
        watchData[i].firebaseid = watchId[i];
        const watchoptions = watchData.map(Watch => ({
          "value": Watch.userAccessToken,
          "label": Watch.watchid
        }));
        this.setState({selectWatchOptions: watchoptions});
      }
    });
  }

  //! 取得日期
  async getCalendarOptions(uat) {
    const response = await axios.get('https://imow.nutc.edu.tw:8009/stresscalendar/' + uat );   // 到 stresscalendar 地址抓取 日期 陣列
    // const response = await axios.get('https://localhost:8080/stresscalendar/' + uat );   // 到 stresscalendar 地址抓取 日期 陣列
    const calendarArray = response.data;  // calendarArray 存放傳回來的 日期陣列
    // 將 日期陣列 中的資料放到 calendaroptions 中
    const calendaroptions = calendarArray.map(Calendar => ({
      "value" : Calendar.id,
      "label": Calendar.calendar,
    }));
    console.log(calendaroptions);
    this.setState({selectCalendarOptions: calendaroptions});
  }

  // ! 取得 Stress 所有資料，uat = userAccessToken
  async getStressOptions(uat, watchid, calendar){
    console.log(uat, watchid, calendar);
    try{
      const response = await axios.post('https://imow.nutc.edu.tw:8009/stressanalyze', {uat, watchid, calendar});
      // const response = await axios.post('https://localhost:8080/stressanalyze', {uat, watchid, calendar});
      if(response.data == ''){
        console.log(response.data);
        this.setState({StressData: response.data});  //將內部陣列存入外層陣列
        alert('無資料');
      } else {
        console.log(response.data);
        this.setState({StressData: response.data}); //將內部陣列存入外層陣列
      }
    }catch(error){
      console.log(error);
    }
  }

  handleChangeWatch(e){
    this.setState({ watch_UAT: e.value, watch_id: e.label });
    // 將使用者選擇的 userAccessToken 傳給 getCalendarOptions
    this.getCalendarOptions(e.value);
  }

  // 改變 this.state.calendar 的資料
  handleChangeCalendar(e){
    this.setState({ calendar_id: e.value, calendar_Date: e.label });
    // 將 userAccessToken 跟 calendar (使用者選擇的日期) 傳給 getStressOptions
    this.getStressOptions(this.state.watch_UAT, this.state.watch_id, e.label);
  }

  componentDidMount(){
    this.getWatchOptions();
  }

  render() {
    const h2={
        fontSize: '2rem',
        fontWeight: 'bold',
        paddingTop: '10px'
    }
    const h4={
        fontSize: '2rem',
        fontWeight: 'bold',
      marginTop: '30px',
        marginBottom: '20px'
    }
    const h3={
        fontSize: '1.5rem',
      fontWeight: 'bold',
        marginTop: '20px',
        marginBottom: '10px'
    }
    const line={
      height: '1px',
      borderTop: 'solid gray 1px',
      margin: '10px 0'
    }
    const textCenter={
      textAlign: 'center',
    }
    return (
      <div>
        <Container>
          <Row className="g-2">
            <Col md>
              <h3 style={h3}>手錶編號：</h3>
              <Select options={this.state.selectWatchOptions} onChange={this.handleChangeWatch.bind(this)} /><br />
            </Col>
            <Col md>
              <h3 style={h3}>選擇日期：</h3>
              <Select options={this.state.selectCalendarOptions} onChange={this.handleChangeCalendar.bind(this)} /><br />
            </Col>
          </Row>
          <h2 style={h2}>{ this.state.calendar_Date } 壓力指數</h2>
          <Container style={line}></Container>    
          <ResponsiveContainer width='100%' height={200}>
            <AreaChart
              width={500}
              height={200}
              data={this.state.StressData}
              margin={{
                top: 10,
                right: 5,
                left: 0,
                bottom: 0,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="Time" />
              <YAxis />
              <Tooltip />
              <Area connectNulls type="monotone" dataKey="Stress" stroke="#8884d8" fill="#8884d8" />
            </AreaChart>
          </ResponsiveContainer>
          <br />
          <div style={textCenter}>
            <span style={{ color: 'black' }}> -2：運動量過大</span>&emsp;
            <span style={{ color: 'black' }}> -1：無資料</span>&emsp;
            <span style={{ color: '#998fc7' }}> 1 ~ 25：休息</span>&emsp;
            <span style={{ color: '#57a773' }}> 26 ~ 50：輕度壓力</span>&emsp;
            <span style={{ color: 'orange' }}> 51 ~ 75：中度壓力</span>&emsp;
            <span style={{ color: 'red' }}> 76 ~ 100：重度壓力</span>&emsp;
          </div>
          {/* 表格 */}
          <h4 style={h4}>資料顯示</h4>
          <Table striped bordered hover style={textCenter}>
            <thead>
              <tr>
                <th>NO</th>
                <th>User</th>
                <th>Calendar</th>
                <th>Time</th>
                <th>Stress</th>
                <th>Stress Level</th>
              </tr>
            </thead>
            <tbody>
              {this.state.StressData.map((Stress, index) => {
                if (Stress.Stress == -2) {
                  return(
                    <tr>
                      <td>{index + 1}</td>
                      <td>{Stress.Watch_id}</td>
                      <td>{Stress.Calendar}</td>
                      <td>{Stress.Time}</td>
                      <td>{Stress.Stress}</td>
                      <td>{Stress.StressLevel}</td>
                    </tr>
                  );
                }
                else if (Stress.StressLevel == '休息') {
                  return(
                    <tr>
                      <td>{index + 1}</td>
                      <td>{Stress.Watch_id}</td>
                      <td>{Stress.Calendar}</td>
                      <td>{Stress.Time}</td>
                      <td>{Stress.Stress}</td>
                      <td style={{ color: '#998fc7' }}>{Stress.StressLevel}</td>
                    </tr>
                  );
                }
                else if (Stress.StressLevel == '輕度壓力') {
                  return(
                    <tr>
                      <td>{index + 1}</td>
                      <td>{Stress.Watch_id}</td>
                      <td>{Stress.Calendar}</td>
                      <td>{Stress.Time}</td>
                      <td>{Stress.Stress}</td>
                      <td style={{ color: '#57a773' }}>{Stress.StressLevel}</td>
                    </tr>
                  );
                }
                else if (Stress.StressLevel == '中度壓力') {
                  return(
                    <tr>
                      <td>{index + 1}</td>
                      <td>{Stress.Watch_id}</td>
                      <td>{Stress.Calendar}</td>
                      <td>{Stress.Time}</td>
                      <td>{Stress.Stress}</td>
                      <td style={{color: 'orange'}}>{Stress.StressLevel}</td>
                    </tr>
                  );
                }
                else if (Stress.StressLevel == '重度壓力') {
                  return(
                    <tr>
                      <td>{index + 1}</td>
                      <td>{Stress.Watch_id}</td>
                      <td>{Stress.Calendar}</td>
                      <td>{Stress.Time}</td>
                      <td>{Stress.Stress}</td>
                      <td style={{ color: 'red' }}>{Stress.StressLevel}</td>
                    </tr>
                  );
                }
              })}   
            </tbody>
          </Table>
        </Container>
      </div>
    );
  }
}
