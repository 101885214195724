 import {BrowserRouter,Switch,Route} from 'react-router-dom';
 import Signin_NER from "./pages_NER/Signin_NER.js";
// 月更新資料
 import MonthList_NER from "./pages_NER/MonthList_NER.js";
 import AddMonth_NER from "./pages_NER/AddMonth_NER.js";
 import EditMonth_NER from "./pages_NER/EditMonth_NER";
// 基本資料
// 護理長
 import PersonalDeveloper_NER from './pages_NER/PersonalDeveloper_NER.js';
 import AddPersonalDeveloper_NER from './pages_NER/AddPersonalDeveloper_NER.js';
 import EditPersonalDeveloper_NER from './pages_NER/EditPersonalDeveloper_NER.js';
// 護理師
 import Personal_NER from './pages_NER/Personal_NER.js';
 import AddPersonal_NER from './pages_NER/AddPersonal_NER.js';
 import EditPersonal_NER from './pages_NER/EditPersonal_NER.js';
// 手錶資料
 import WatchList_NER from './pages_NER/WatchList_NER.js';
 import AddWatch_NER from "./pages_NER/AddWatch_NER.js";
 import EditWatch_NER from "./pages_NER/EditWatch_NER.js";
// 數據分析
 import Analyze_NER from './pages_NER/Analyze_NER.js';
 import AnalyzeHeartRate_NER from './pages_NER/Analyze_HeartRate_NER.js';
 import AnalyzeSleep_NER from './pages_NER/Analyze_Sleep_NER.js';
 import AnalyzeStress_NER from './pages_NER/Analyze_Stress_NER.js';
 import Picture_NER from './pages_NER/Picture_NER.js';
 import PictureBox_NER from './pages_NER/Picture_Box_NER.js';

function App_NER() {
  return (
    <BrowserRouter>
      <Switch>        
        {/* 登入 */}
        <Route path='/ner' exact>
          <Signin_NER/>
        </Route>

        {/* 月更新資料 */}
        <Route path='/ner/month' exact>
          <MonthList_NER/>
        </Route>
        <Route path='/ner/addmonth' exact>
          <AddMonth_NER/>
        </Route>
        <Route exact path='/ner/editmonth/:firebaseid' component={EditMonth_NER} >
        </Route> 

        {/* 基本資料 */}
        {/* 護理長看到畫面 */}
        <Route path='/ner/personalDeveloper' exact>
          <PersonalDeveloper_NER/>
        </Route>
        <Route path='/ner/addpersonalDeveloper' exact>
          <AddPersonalDeveloper_NER/>
        </Route>
        <Route path='/ner/editpersonalDeveloper/:account' component={EditPersonalDeveloper_NER} exact></Route>

        {/* 護理師看到畫面 */}
        <Route path='/ner/personal/:account' component={Personal_NER} exact></Route>
        <Route path='/ner/addpersonal/:account' component={AddPersonal_NER} exact></Route>
        <Route path='/ner/editpersonal/:account' component={EditPersonal_NER} exact></Route> 

        {/* 數據分析 */}
         <Route path ='/ner/analyze' exact>
          <Analyze_NER/>
        </Route>
        <Route path ='/ner/Picture' exact>
          <Picture_NER/>
        </Route> 
        {/* 心率指數 */}
       <Route path='/ner/analyze/heartrate' exact>
          <AnalyzeHeartRate_NER />
        </Route> 
        {/* 睡眠狀態 */}
        <Route path='/ner/analyze/sleep' exact>
          <AnalyzeSleep_NER />
        </Route> 
        {/* 壓力指數 */}
         <Route path='/ner/analyze/stress' exact>
          <AnalyzeStress_NER />
        </Route>
        <Route path='/ner/Picture/Box' exact>
          <PictureBox_NER />
        </Route> 
        
        {/* 手錶資料 */}
         <Route path='/ner/watch' exact>
          <WatchList_NER/>
        </Route>
        <Route path='/ner/addwatch' exact>
          <AddWatch_NER/>
        </Route>
        <Route path='/ner/editwatch/:firebaseid' component={EditWatch_NER} exact></Route> 
        
      </Switch>
    </BrowserRouter>
  );
}

export default App_NER;
