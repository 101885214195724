 import {BrowserRouter,Switch,Route} from 'react-router-dom';
 import Signin_AR from "./pages_AR/Signin_AR.js";
// 月更新資料
 import MonthList_AR from "./pages_AR/MonthList_AR.js";
 import AddMonth_AR from "./pages_AR/AddMonth_AR.js";
 import EditMonth_AR from "./pages_AR/EditMonth_AR.js";
// 基本資料
// 護理長
 import PersonalDeveloper_AR from './pages_AR/PersonalDeveloper_AR.js';
 import AddPersonalDeveloper_AR from './pages_AR/AddPersonalDeveloper_AR.js';
 import EditPersonalDeveloper_AR from './pages_AR/EditPersonalDeveloper_AR.js';
// 護理師
 import Personal_AR from './pages_AR/Personal_AR.js';
 import AddPersonal_AR from './pages_AR/AddPersonal_AR.js';
 import EditPersonal_AR from './pages_AR/EditPersonal_AR.js';
// 手錶資料
 import WatchList_AR from './pages_AR/WatchList_AR.js';
 import AddWatch_AR from "./pages_AR/AddWatch_AR.js";
 import EditWatch_AR from "./pages_AR/EditWatch_AR.js";
// 數據分析
 import Analyze_AR from './pages_AR/Analyze_AR.js';
 import AnalyzeHeartRate_AR from './pages_AR/Analyze_HeartRate_AR.js';
 import AnalyzeSleep_AR from './pages_AR/Analyze_Sleep_AR.js';
 import AnalyzeStress_AR from './pages_AR/Analyze_Stress_AR.js';
 import Picture_AR from './pages_AR/Picture_AR.js';
 import PictureBox_AR from './pages_AR/Picture_Box_AR.js';

function App_AR() {
  return (
    <BrowserRouter>
      <Switch>        
        {/* 登入 */}
        <Route path='/ar' exact>
          <Signin_AR/>
        </Route>

        {/* 月更新資料 */}
        <Route path='/ar/month' exact>
          <MonthList_AR/>
        </Route>
        <Route path='/ar/addmonth' exact>
          <AddMonth_AR/>
        </Route>
        <Route exact path='/ar/editmonth/:firebaseid' component={EditMonth_AR} >
        </Route> 

        {/* 基本資料 */}
        {/* 護理長看到畫面 */}
        <Route path='/ar/personalDeveloper' exact>
          <PersonalDeveloper_AR/>
        </Route>
        <Route path='/ar/addpersonalDeveloper' exact>
          <AddPersonalDeveloper_AR/>
        </Route>
        <Route path='/ar/editpersonalDeveloper/:account' component={EditPersonalDeveloper_AR} exact></Route>

        {/* 護理師看到畫面 */}
        <Route path='/ar/personal/:account' component={Personal_AR} exact></Route>
        <Route path='/ar/addpersonal/:account' component={AddPersonal_AR} exact></Route>
        <Route path='/ar/editpersonal/:account' component={EditPersonal_AR} exact></Route> 

        {/* 數據分析 */}
         <Route path ='/ar/analyze' exact>
          <Analyze_AR/>
        </Route>
        <Route path ='/ar/Picture' exact>
          <Picture_AR/>
        </Route> 
        {/* 心率指數 */}
       <Route path='/ar/analyze/heartrate' exact>
          <AnalyzeHeartRate_AR/>
        </Route> 
        {/* 睡眠狀態 */}
        <Route path='/ar/analyze/sleep' exact>
          <AnalyzeSleep_AR/>
        </Route> 
        {/* 壓力指數 */}
         <Route path='/ar/analyze/stress' exact>
          <AnalyzeStress_AR/>
        </Route>
        <Route path='/ar/Picture/Box' exact>
          <PictureBox_AR/>
        </Route> 
        
        {/* 手錶資料 */}
         <Route path='/ar/watch' exact>
          <WatchList_AR/>
        </Route>
        <Route path='/ar/addwatch' exact>
          <AddWatch_AR/>
        </Route>
        <Route path='/ar/editwatch/:firebaseid' component={EditWatch_AR} exact></Route> 
        
      </Switch>
    </BrowserRouter>
  );
}

export default App_AR;
