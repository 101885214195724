 import {BrowserRouter,Switch,Route} from 'react-router-dom';
 import Signin_ER from "./pages_ER/Signin_ER.js";
// 月更新資料
 import MonthList_ER from "./pages_ER/MonthList_ER.js";
 import AddMonth_ER from "./pages_ER/AddMonth_ER.js";
 import EditMonth_ER from "./pages_ER/EditMonth_ER";
// 基本資料
// 護理長
 import PersonalDeveloper_ER from './pages_ER/PersonalDeveloper_ER.js';
 import AddPersonalDeveloper_ER from './pages_ER/AddPersonalDeveloper_ER.js';
 import EditPersonalDeveloper_ER from './pages_ER/EditPersonalDeveloper_ER.js';
// 護理師
 import Personal_ER from './pages_ER/Personal_ER.js';
 import AddPersonal_ER from './pages_ER/AddPersonal_ER.js';
 import EditPersonal_ER from './pages_ER/EditPersonal_ER.js';
// 手錶資料
 import WatchList_ER from './pages_ER/WatchList_ER.js';
 import AddWatch_ER from "./pages_ER/AddWatch_ER.js";
 import EditWatch_ER from "./pages_ER/EditWatch_ER.js";
// 數據分析
 import Analyze_ER from './pages_ER/Analyze_ER.js';
 import AnalyzeHeartRate_ER from './pages_ER/Analyze_HeartRate_ER.js';
 import AnalyzeSleep_ER from './pages_ER/Analyze_Sleep_ER.js';
 import AnalyzeStress_ER from './pages_ER/Analyze_Stress_ER.js';
 import Picture_ER from './pages_ER/Picture_ER.js';
 import PictureBox_ER from './pages_ER/Picture_Box_ER.js';

function App_ER() {
  return (
    <BrowserRouter>
      <Switch>        
        {/* 登入 */}
        <Route path='/er' exact>
          <Signin_ER/>
        </Route>

        {/* 月更新資料 */}
        <Route path='/er/month' exact>
          <MonthList_ER/>
        </Route>
        <Route path='/er/addmonth' exact>
          <AddMonth_ER/>
        </Route>
        <Route exact path='/er/editmonth/:firebaseid' component={EditMonth_ER} >
        </Route> 

        {/* 基本資料 */}
        {/* 護理長看到畫面 */}
        <Route path='/er/personalDeveloper' exact>
          <PersonalDeveloper_ER/>
        </Route>
        <Route path='/er/addpersonalDeveloper' exact>
          <AddPersonalDeveloper_ER/>
        </Route>
        <Route path='/er/editpersonalDeveloper/:account' component={EditPersonalDeveloper_ER} exact></Route>

        {/* 護理師看到畫面 */}
        <Route path='/er/personal/:account' component={Personal_ER} exact></Route>
        <Route path='/er/addpersonal/:account' component={AddPersonal_ER} exact></Route>
        <Route path='/er/editpersonal/:account' component={EditPersonal_ER} exact></Route> 

        {/* 數據分析 */}
         <Route path ='/er/analyze' exact>
          <Analyze_ER/>
        </Route>
        <Route path ='/er/Picture' exact>
          <Picture_ER/>
        </Route> 
        {/* 心率指數 */}
       <Route path='/er/analyze/heartrate' exact>
          <AnalyzeHeartRate_ER />
        </Route> 
        {/* 睡眠狀態 */}
        <Route path='/er/analyze/sleep' exact>
          <AnalyzeSleep_ER />
        </Route> 
        {/* 壓力指數 */}
         <Route path='/er/analyze/stress' exact>
          <AnalyzeStress_ER />
        </Route>
        <Route path='/er/Picture/Box' exact>
          <PictureBox_ER />
        </Route> 
        
        {/* 手錶資料 */}
         <Route path='/er/watch' exact>
          <WatchList_ER/>
        </Route>
        <Route path='/er/addwatch' exact>
          <AddWatch_ER/>
        </Route>
        <Route path='/er/editwatch/:firebaseid' component={EditWatch_ER} exact></Route> 
        
      </Switch>
    </BrowserRouter>
  );
}

export default App_ER;
