 import {BrowserRouter,Switch,Route} from 'react-router-dom';
 import Signin_NAR from "./pages_NAR/Signin_NAR.js";
// 月更新資料
 import MonthList_NAR from "./pages_NAR/MonthList_NAR.js";
 import AddMonth_NAR from "./pages_NAR/AddMonth_NAR.js";
 import EditMonth_NAR from "./pages_NAR/EditMonth_NAR.js";
// 基本資料
// 護理長
 import PersonalDeveloper_NAR from './pages_NAR/PersonalDeveloper_NAR.js';
 import AddPersonalDeveloper_NAR from './pages_NAR/AddPersonalDeveloper_NAR.js';
 import EditPersonalDeveloper_NAR from './pages_NAR/EditPersonalDeveloper_NAR.js';
// 護理師
 import Personal_NAR from './pages_NAR/Personal_NAR.js';
 import AddPersonal_NAR from './pages_NAR/AddPersonal_NAR.js';
 import EditPersonal_NAR from './pages_NAR/EditPersonal_NAR.js';
// 手錶資料
 import WatchList_NAR from './pages_NAR/WatchList_NAR.js';
 import AddWatch_NAR from "./pages_NAR/AddWatch_NAR.js";
 import EditWatch_NAR from "./pages_NAR/EditWatch_NAR.js";
// 數據分析
 import Analyze_NAR from './pages_NAR/Analyze_NAR.js';
 import AnalyzeHeartRate_NAR from './pages_NAR/Analyze_HeartRate_NAR.js';
 import AnalyzeSleep_NAR from './pages_NAR/Analyze_Sleep_NAR.js';
 import AnalyzeStress_NAR from './pages_NAR/Analyze_Stress_NAR.js';
 import Picture_NAR from './pages_NAR/Picture_NAR.js';
 import PictureBox_NAR from './pages_NAR/Picture_Box_NAR.js';

function App_NAR() {
  return (
    <BrowserRouter>
      <Switch>        
        {/* 登入 */}
        <Route path='/nar' exact>
          <Signin_NAR/>
        </Route>

        {/* 月更新資料 */}
        <Route path='/nar/month' exact>
          <MonthList_NAR/>
        </Route>
        <Route path='/nar/addmonth' exact>
          <AddMonth_NAR/>
        </Route>
        <Route exact path='/nar/editmonth/:firebaseid' component={EditMonth_NAR} >
        </Route> 

        {/* 基本資料 */}
        {/* 護理長看到畫面 */}
        <Route path='/nar/personalDeveloper' exact>
          <PersonalDeveloper_NAR/>
        </Route>
        <Route path='/nar/addpersonalDeveloper' exact>
          <AddPersonalDeveloper_NAR/>
        </Route>
        <Route path='/nar/editpersonalDeveloper/:account' component={EditPersonalDeveloper_NAR} exact></Route>

        {/* 護理師看到畫面 */}
        <Route path='/nar/personal/:account' component={Personal_NAR} exact></Route>
        <Route path='/nar/addpersonal/:account' component={AddPersonal_NAR} exact></Route>
        <Route path='/nar/editpersonal/:account' component={EditPersonal_NAR} exact></Route> 

        {/* 數據分析 */}
         <Route path ='/nar/analyze' exact>
          <Analyze_NAR/>
        </Route>
        <Route path ='/nar/Picture' exact>
          <Picture_NAR/>
        </Route> 
        {/* 心率指數 */}
       <Route path='/nar/analyze/heartrate' exact>
          <AnalyzeHeartRate_NAR/>
        </Route> 
        {/* 睡眠狀態 */}
        <Route path='/nar/analyze/sleep' exact>
          <AnalyzeSleep_NAR/>
        </Route> 
        {/* 壓力指數 */}
         <Route path='/nar/analyze/stress' exact>
          <AnalyzeStress_NAR/>
        </Route>
        <Route path='/nar/Picture/Box' exact>
          <PictureBox_NAR/>
        </Route> 
        
        {/* 手錶資料 */}
         <Route path='/nar/watch' exact>
          <WatchList_NAR/>
        </Route>
        <Route path='/nar/addwatch' exact>
          <AddWatch_NAR/>
        </Route>
        <Route path='/nar/editwatch/:firebaseid' component={EditWatch_NAR} exact></Route> 
        
      </Switch>
    </BrowserRouter>
  );
}

export default App_NAR;
