// ! 給護理長看的畫面
import { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import Headers from '../Headers_NER.js';
// bootstrapt
import { Table, Container, Row, Col, Button } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
// 連接 Firebase
import firebase from '../config/database_ER.js';
import 'firebase/compat/firestore';
// 取得 Firebase 中的 Personal 資料表
const ref = firebase.firestore().collection('Personal');

const PersonalList = () => {
    const [personals, setPersonals] = useState([]);
    useEffect(()=>{
        getPersonals();
    },[]);

    // 取得所有 基本資料
    // 取得所有 基本資料
    const getPersonals = async () =>{
        const personalsData = [];
        const personalsId = [];
        
        // 使用 get() 的方式，一次性讀取資料
        ref.orderBy('watchid').get().then(items => {
            items.forEach(doc => {
                personalsId.push(doc.id);
                personalsData.push(doc.data());
            });
            setPersonals(personalsData);
        });
    };

    // 刪除 單筆基本資料
    const deletePersonal = async (account) =>{
        ref.doc(account).delete().then(() => {
            alert('刪除成功！');
        });
        window.setTimeout(( () => window.location.reload() ), 1250);
    }
    const h2={
        fontSize: '2rem',
        fontWeight: 'bold',
        paddingTop: '10px'
    }
    const h3={
        fontSize: '2rem',
        fontWeight: 'bold',
        paddingTop: '50px',
        marginBottom: '-10px'
    }
    const line={
        height: '1px',
        borderTop: 'solid gray 1px',
        margin: '10px 0'
    }
    const blue={
        fontSize: '1rem',
        fontWeight: 'bold',
        color: 'blue'
    }
    const button={
        margin: '5px',
    }
    const textCenter = {
        textAlign: 'center',
    }
    return(
        <div>
            <Headers/>
            <Container fluid="md">
                <Row>
                    <Col>
                        <h2 style={h2}>急診室 基本資料</h2>
                        <h3 style={blue}>[手環從No.1號(GarminWatch31)~No.20號(GarminWatch50)為第一梯次第1部分]</h3>
                        <h3 style={blue}>[手環從No.21號(GarminWatch91)~No.40號(GarminWatch110)為第一梯次第2部分]</h3>
                        <Container style={line}></Container>
                        {/* <Link to="/addpersonalDeveloper"><Button variant="outline-success" size="lg">新增</Button></Link> */}
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <h3 style={h3}>資料顯示</h3>
                        <br/>
                        <Table striped bordered hover style={textCenter}>
                            <thead>
                                <tr>
                                    <th>No.</th>
                                    <th>使用者</th>
                                    <th>性別</th>
                                    <th>年齡</th>
                                    <th>婚姻狀況</th>
                                    <th>有無小孩</th>
                                    <th>教育程度</th>
                                    <th>部門(科別)</th>
                                    <th>職稱</th>
                                    <th>薪資</th>
                                    <th>建檔日期</th>
                                    <th>修改日期</th>
                                    <th>修改刪除</th>
                                </tr>
                            </thead>
                            <tbody>
                                {personals.map((Personal,index)=> {
                                    return (
                                        <tr key={Personal.account}>
                                            <td>{index + 1}</td>
                                            <td>{Personal.watchid}</td>
                                            <td>{Personal.sex}</td>
                                            <td>{Personal.age}</td>
                                            <td>{Personal.marriage}</td>
                                            <td>{Personal.children}</td>
                                            <td>{Personal.education}</td>
                                            <td>{Personal.department}</td>
                                            <td>{Personal.position}</td>
                                            <td>{Personal.salary}</td>
                                            <td>{Personal.createdTime}</td>
                                            <td>{Personal.editedTime}</td>
                                            <td>
                                                <Link to={'/ner/editpersonalDeveloper/' + encodeURIComponent(Personal.account)}><Button variant="warning" style={button}>修改</Button></Link>
                                                <Button onClick={() => deletePersonal(Personal.account)} variant="outline-danger" >刪除</Button>
                                            </td>
                                        </tr>
                                    );
                                })}   
                            </tbody>
                        </Table>
                    </Col>
                </Row>
            </Container>
        </div>
    );
    
}
export default PersonalList;
