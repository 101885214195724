import firebase_ER from 'firebase/compat/app';

const firebaseConfig_ER = {
  apiKey: "AIzaSyCZMiM5gB0nmQgTVBvG0lJc5ioovLRoix4",
  authDomain: "fir-weber.firebaseapp.com",
  databaseURL: "https://fir-weber-default-rtdb.firebaseio.com",
  projectId: "fir-weber",
  storageBucket: "fir-weber.appspot.com",
  messagingSenderId: "19575722155",
  appId: "1:19575722155:web:4518059d3d6de6496be7ee",
  measurementId: "G-2XZ49YKXQB"
};

const FirebaseER = firebase_ER.initializeApp(firebaseConfig_ER,'other');


export default FirebaseER;
