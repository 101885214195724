import React from 'react';
import ReactDOM from 'react-dom';
import App from './App.js';
import App_ER from './App_ER.js';
import App_AR from './App_AR.js';
import App_N from './App_N.js';
import App_NER from './App_NER.js';
import App_NAR from './App_NAR.js';
import "bulma/css/bulma.css";

ReactDOM.render(
  <React.StrictMode>
    <App />
    <App_ER />
    <App_AR />
    <App_N />
    <App_NER />
    <App_NAR />
  </React.StrictMode>,
  document.getElementById('root')
);


