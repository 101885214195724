import {BrowserRouter,Switch,Route} from 'react-router-dom';
import Signin from "./pages/Signin.js";
// 月更新資料
import MonthList from "./pages/MonthList.js";
import AddMonth from "./pages/AddMonth.js";
import EditMonth from "./pages/EditMonth.js";
// 基本資料
// 護理長
import PersonalDeveloper from './pages/PersonalDeveloper.js';
import AddPersonalDeveloper from './pages/AddPersonalDeveloper.js';
import EditPersonalDeveloper from './pages/EditPersonalDeveloper.js';
// 護理師
import Personal from './pages/Personal.js';
import AddPersonal from './pages/AddPersonal.js';
import EditPersonal from './pages/EditPersonal.js';
// 手錶資料
import WatchList from './pages/WatchList.js';
import AddWatch from "./pages/AddWatch.js";
import EditWatch from "./pages/EditWatch.js";
// 數據分析
import Analyze from './pages/Analyze.js';
import AnalyzeHeartRate from './pages/Analyze_HeartRate.js';
import AnalyzeSleep from './pages/Analyze_Sleep.js';
import AnalyzeStress from './pages/Analyze_Stress.js';
import Picture from './pages/Picture.js';
import PictureBox from './pages/Picture_Box.js';

function App() {
  return (
    <BrowserRouter>
      <Switch>        
        {/* 登入 */}
        <Route path='/' exact>
          <Signin/>
        </Route>
        {/* 月更新資料 */}
        <Route path='/month' exact>
          <MonthList/>
        </Route>
        <Route path='/addmonth' exact>
          <AddMonth/>
        </Route>
        <Route exact path='/editmonth/:firebaseid' component={EditMonth} ></Route>
        {/* 基本資料 */}
        {/* 護理長看到畫面 */}
        <Route path='/personalDeveloper' exact>
          <PersonalDeveloper/>
        </Route>
        <Route path='/addpersonalDeveloper' exact>
          <AddPersonalDeveloper/>
        </Route>
        <Route path='/editpersonalDeveloper/:account' component={EditPersonalDeveloper} exact></Route>
        {/* 護理師看到畫面 */}
        <Route path='/personal/:account' component={Personal} exact></Route>
        <Route path='/addpersonal/:account' component={AddPersonal} exact></Route>
        <Route path='/editpersonal/:account' component={EditPersonal} exact></Route>
        {/* 數據分析 */}
        <Route path ='/analyze' exact>
          <Analyze/>
        </Route>
        <Route path ='/Picture' exact>
          <Picture/>
        </Route>
        {/* 心率指數 */}
        <Route path='/analyze/heartrate' exact>
          <AnalyzeHeartRate />
        </Route>
        {/* 睡眠狀態 */}
        <Route path='/analyze/sleep' exact>
          <AnalyzeSleep />
        </Route>
        {/* 壓力指數 */}
        <Route path='/analyze/stress' exact>
          <AnalyzeStress />
        </Route>
        <Route path='/Picture/Box' exact>
          <PictureBox />
        </Route>
                {/* 手錶資料 */}
        <Route path='/watch' exact>
          <WatchList/>
        </Route>
        <Route path='/addwatch' exact>
          <AddWatch/>
        </Route>
        <Route path='/editwatch/:firebaseid' component={EditWatch} exact></Route>
        
      </Switch>
    </BrowserRouter>
  );
}

export default App;
