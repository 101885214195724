import React, { Component } from 'react';
import Select from 'react-select';
import axios from 'axios';
import { Link} from 'react-router-dom';
import Headers from '../Headers_NoTopics.js';
// bootstrapt
import { Form, Container, Row, Col, Button } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
// 連接 Firebase
import firebase from '../config/database_ER.js';
import 'firebase/compat/firestore';
const ref = firebase.firestore();

class AddPersonal extends Component {
  constructor(props){
    super(props)
    this.state = {
      account:'',
      selectWatchOptions : [],        // 手錶使用者選單 
      watch_watchid: '',              // 手錶使用者選擇
      selectSexOptions : [],          // 性別
      sex_name: '',                   // 性別選擇
      selectAgeOptions : [],          // 年齡
      age_name: '',                   // 年齡選擇
      selectMarriageOptions : [],     // 婚姻狀況
      marriage_name: '',              // 婚姻狀況選擇
      selectChildrenOptions : [],     // 有無小孩
      children_name: '',              // 有無孩子選擇
      selectPositionOptions : [],     // 職稱
      position_name: '',              // 職稱選擇
      selectEducationOptions : [],    // 教育程度
      education_name: '',             //教育程度選擇
      selectDepartmentOptions : [],   // 部門
      department_name: '',            // 部門選擇
      selectSalaryOptions : [],       // 薪資
      salary_name: '',                // 薪資選擇
      //以下為使用者輸入資料變數
      watchid:'',    //手錶使用者
      sex:'',        //性別
      age:'',        //年齡
      marriage:'',   //婚姻狀態
      children:'',   //孩子有無
      department:'', //部門
      education:'',  //教育程度
      position:'',   //職稱
      salary: '',     //薪資
      uat: ''        // userAccessToken
    }
  }
//以下為從資料庫取得下拉選單
  async getAccount(Account) {
    this.setState({ account: Account });
  }
  //取得 手錶資料
  async getWatchOptions(){
    const watchData = [];
    const watchId = [];
    // 使用 get() 的方式，一次性讀取資料
    ref.collection('Watch').orderBy('account').get().then(items => {
      items.forEach(doc => {
        watchId.push(doc.id);
        watchData.push(doc.data());
      });
      //將從資料庫取得的資料存進watchoption陣列
      for (let i = 0; i < watchData.length; i++){
        const watchoptions = watchData.map(Watch => ({
          "value": Watch.id,
          "label": Watch.watchid
        }));
        //需將selectWatchOptions資料為watchoptions，外層才可以使用
        this.setState({selectWatchOptions: watchoptions});
        this.handleSubmit = this.handleSubmit.bind(this);
        // console.log(watchData[i]);
      }
    });
  }

  //get 性別
  async getSexOptions(){
    const data = [];
    ref.collection('SexMenu').orderBy('id').onSnapshot(querySnapshot => {
      querySnapshot.forEach(doc => {
        data.push(doc.data());
      });
      const sexoptions = data.map(Sex => ({
      "value" : Sex.id,
      "label" : Sex.name
      }))
      this.setState({selectSexOptions: sexoptions});
      this.handleSubmit = this.handleSubmit.bind(this);
    });
    
  }

  // get 年齡
  async getAgeOptions(){ 
    const data = [];
    ref.collection('AgeMenu').orderBy('id').onSnapshot(querySnapshot => {
      querySnapshot.forEach(doc => {
        data.push(doc.data());
      });
      const ageoptions = data.map(Age => ({
        "value" : Age.id,
        "label" : Age.name
      }))
      this.setState({selectAgeOptions: ageoptions});
      this.handleSubmit = this.handleSubmit.bind(this);
    });
  }

  // get 婚姻狀況
  async getMarriageOptions(){
    const data = [];
    ref.collection('MarriageMenu').orderBy('id').onSnapshot(querySnapshot => {
      querySnapshot.forEach(doc => {
        data.push(doc.data());
      });
      const marriageoptions = data.map(Marriage => ({
        "value" : Marriage.id,
        "label" : Marriage.name
      }))
      this.setState({selectMarriageOptions: marriageoptions});
      this.handleSubmit = this.handleSubmit.bind(this);
    }); 
  }

  // get 有無小孩
  async getChildrenOptions(){
    const data = [];
    ref.collection('ChildrenMenu').orderBy('id').onSnapshot(querySnapshot => {
      querySnapshot.forEach(doc => {
        data.push(doc.data());
      });
      const childrenoptions = data.map(Children => ({
        "value" : Children.id,
        "label" : Children.name
      }))
      this.setState({selectChildrenOptions: childrenoptions});
      this.handleSubmit = this.handleSubmit.bind(this);
    });
  }

  // get 職稱
  async getPositionOptions(){
    const data = [];
    ref.collection('PositionMenu').orderBy('id').onSnapshot(querySnapshot => {
      querySnapshot.forEach(doc => {
        data.push(doc.data());
      });
      const positionoptions = data.map(Position => ({
        "value" : Position.id,
        "label" : Position.name
      }))
      this.setState({selectPositionOptions: positionoptions});
      this.handleSubmit = this.handleSubmit.bind(this);
    });
  }

  // get 部門
  async getDepartmentOptions(){
    const data = [];
    ref.collection('DepartmentMenu').orderBy('id').onSnapshot(querySnapshot => {
      querySnapshot.forEach(doc => {
        data.push(doc.data());
      });
      const departmentoptions = data.map(Department => ({
        "value" : Department.id,
        "label" : Department.name
      }))
      this.setState({selectDepartmentOptions: departmentoptions});
      this.handleSubmit = this.handleSubmit.bind(this);
    }); 
  }

  // get 教育程度
  async getEducationOptions(){
    const data = [];
    ref.collection('EducationMenu').orderBy('id').onSnapshot(querySnapshot => {
      querySnapshot.forEach(doc => {
        data.push(doc.data());
      });
      const educationoptions = data.map(Education => ({
        "value" : Education.id,
        "label" : Education.name
      }))
      this.setState({selectEducationOptions: educationoptions});
      this.handleSubmit = this.handleSubmit.bind(this);
    });
  }

  // get 薪資
  async getSalaryOptions(){
    const data = [];
    ref.collection('SalaryMenu').orderBy('id').onSnapshot(querySnapshot => {
      querySnapshot.forEach(doc => {
        data.push(doc.data());
      });
      const salaryoptions = data.map(Salary => ({
        "value" : Salary.id,
        "label" : Salary.name
      }))
      this.setState({selectSalaryOptions: salaryoptions});
      this.handleSubmit = this.handleSubmit.bind(this);
    });
  }

  //handleChange...(e) 用於儲存變動的便數資料
  handleChangeWatch(e){
    this.setState({ watch_watchid: e.label });
    const watchData = [];
    // 取得 firebase 中的 Watch 資料，並存入 watchData 中
    ref.collection('Watch').get().then(items => {
      items.forEach(doc => {
        watchData.push(doc.data());
      });
      for (let i = 0; i < watchData.length; i++){
        if (e.label == watchData[i].watchid) {
          this.setState({uat: watchData[i].userAccessToken});
        }
      }
    });
    
  }
  handleChangeSex(e){
    this.setState({sex_name:e.label})
  }
  handleChangeAge(e){
    this.setState({age_name:e.label})
  }
  handleChangeMarriage(e){
    this.setState({marriage_name:e.label})
  }
  handleChangeChildren(e){
    this.setState({children_name:e.label})
  }
  handleChangeDepartment(e){
    this.setState({department_name:e.label})
  }
  handleChangePosition(e){
    this.setState({position_name:e.label})
  }
  handleChangeEducation(e){
    this.setState({education_name:e.label})
  }
  handleChangeSalary(e){
    this.setState({ salary_name: e.label })
  }
  
  //新增資料到資料庫
  handleSubmit(e) {
    const today = new Date();
    const date = today.getFullYear() + '/' + (today.getMonth() + 1) + '/' + today.getDate() + '     ' + today.getHours() + ':' + today.getMinutes() + ':' + today.getSeconds() + ' ';
    if(this.state.watch_watchid == '' || this.state.sex_name == '' || this.state.age_name == '' || this.state.marriage_name == '' || this.state.children_name == '' || this.state.department_name == '' || this.state.education_name == '' || this.state.position_name == '' || this.state.salary_name == ''){
      alert('有資料未填寫！');
      e.preventDefault(); 
    }else{
      ref.collection('Personal').doc(this.state.account).set({
        "account": this.state.account,    // 帳號
        "watchid": this.state.watch_watchid,  // 手錶
        "sex":this.state.sex_name,    // 性別
        "age":this.state.age_name,    // 年齡
        "marriage":this.state.marriage_name,    // 婚姻狀況
        "children":this.state.children_name,    // 有無小孩
        "department":this.state.department_name,   // 部門
        "education":this.state.education_name,     // 教育程度
        "position":this.state.position_name,       // 職稱
        "salary": this.state.salary_name,       // 薪資
        "uat":this.state.uat,         // userAccessToken
        "createdTime": date,        // 建檔日期
      })
      console.log("新增成功");
    }
  }
  
  //進入頁面自動執行
  componentDidMount() {
    this.getAccount(decodeURIComponent(this.props.match.params.account))
    this.getWatchOptions()
    this.getSexOptions()
    this.getAgeOptions()
    this.getMarriageOptions()
    this.getChildrenOptions()
    this.getDepartmentOptions()
    this.getEducationOptions()
    this.getPositionOptions()
    this.getSalaryOptions()
  }

  

  render() {
    const h2={
        fontSize: '2rem',
        fontWeight: 'bold',
        paddingTop: '10px'
    }
    const line={
      height: '1px',
      borderTop: 'solid gray 1px',
      margin: '10px 0'
    }
    const margin={
      margin: '10px',
    }
    const h4={
      fontSize: '1.5rem',
      fontWeight: 'bold',
    }
    const p={
      fontSize: '1.5rem',
      color: '#50a9b9'
    }
    const button={
      margin: '5px',
      float:'right'
    }
    return(
      <div>
        <Headers />
        <Container>
          <h2 style={h2}>新增基本資料</h2>
          <Container style={line}></Container>
          <Form style={margin}>
            {/**使用者選擇下拉選單 */}
            <Row className="g-3">
              <Col md>
                <p style={h4}>手錶編號：</p>
                <Select options={this.state.selectWatchOptions} onChange={this.handleChangeWatch.bind(this)} /><br/>
                <p style={h4}>選擇性別：</p>
                <Select options={this.state.selectSexOptions} onChange={this.handleChangeSex.bind(this)} /><br/>
                <p style={h4}>選擇年齡：</p>
                <Select options={this.state.selectAgeOptions} onChange={this.handleChangeAge.bind(this)} /><br/>
              </Col>
              <Col md>
                <p style={h4}>婚姻狀態：</p>
                <Select options={this.state.selectMarriageOptions} onChange={this.handleChangeMarriage.bind(this)} /><br/>
                <p style={h4}>孩子有無：</p>
                <Select options={this.state.selectChildrenOptions} onChange={this.handleChangeChildren.bind(this)} /><br/>
                <p style={h4}>最高學歷：</p>
                <Select options={this.state.selectEducationOptions} onChange={this.handleChangeEducation.bind(this)} /><br/>
              </Col>
              <Col md>
                <p style={h4}>所屬部門：</p>
                <Select options={this.state.selectDepartmentOptions} onChange={this.handleChangeDepartment.bind(this)} /><br/>
                <p style={h4}>職位名稱：</p>
                <Select options={this.state.selectPositionOptions} onChange={this.handleChangePosition.bind(this)} /><br/>
                <p style={h4}>薪資範圍：</p>
                <Select options={this.state.selectSalaryOptions} onChange={this.handleChangeSalary.bind(this)} /><br/>
              </Col>
            </Row>
            <br/>
            

            {/**顯示使用者選擇選項 */}
            <Form style={h4}>
              <h2 style={h2}>請確認以下資料：</h2>
              <Container style={line}></Container>
              <Row>
                <Col md>
                  手錶編號 ：<span style={p}>{this.state.watch_watchid}</span><br/>
                  選擇性別 ：<span style={p}>{this.state.sex_name}</span><br/>
                  選擇年齡 ：<span style={p}>{this.state.age_name}</span><br/>
                </Col>
                <Col md>
                  婚姻狀態 ：<span style={p}>{this.state.marriage_name}</span><br/>
                  孩子有無 ：<span style={p}>{this.state.children_name}</span><br/>
                  最高學歷 ：<span style={p}>{this.state.education_name}</span><br/>
                </Col>
                <Col md>
                  所屬部門 ：<span style={p}>{this.state.department_name}</span><br/>
                  職位名稱 ：<span style={p}>{this.state.position_name}</span><br/>
                  薪資範圍 ：<span style={p}>{this.state.salary_name}</span><br/>
                </Col>
              </Row>
            </Form><br/>
            <Link to={'/ner/personal/'+encodeURIComponent(this.state.account)}><Button onClick={this.handleSubmit} variant="outline-success" style={button} size="lg">新增</Button></ Link>
          </Form>
        </Container>
      </div>
    );
  }
  
}
export default AddPersonal; //將AddPersonal 渲染出去
