import React, { Component } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import Headers from '../Headers_NER.js';
// bootstrapt
import { Form, Container, Row, Col, Button } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
// Firebase
import firebase from '../config/database_ER.js';
import 'firebase/compat/firestore';
// 取得 Firebase 中的 Personal 資料表
const ref = firebase.firestore();

//新增手錶頁(僅後端使用者可讀寫)
class AddWatch extends Component {
  constructor(props) {
    super(props)
    this.state = {
      id: '',
      watchid: 'GarminWatch',
      account: '',
      password: '',
      userAccessToken: '',
      userAccessTokenSecret: '',
      maxid: '',
    };
  }

  async getMaxWatchNum() {
    const watchNumData = [];
    // const watchId = [];
    // 使用 get() 的方式，一次性讀取資料
    ref.collection('MaxWatchNum').doc('MaxWatchId').get().then(items => {
      this.setState({ maxid: items.data().maxid });
    this.handleSubmit = this.handleSubmit.bind(this);
    });
    
  };
  
  //handleChange...(e) 用於儲存變動的便數資料
  handleChangeID(e){
    this.setState({ id: e.target.value });
    // this.handleSubmit = this.handleSubmit.bind(this);
    };
  handleChangeAccount(e){
    this.setState({ account: e.target.value });
    };
  handleChangeWatchId(e){
    this.setState({ watchid: e.target.value });
  }
  handleChangePassword(e){
    this.setState({ password: e.target.value });
  }
  handleChangeUserAccessToken(e){
    this.setState({ userAccessToken: e.target.value });
  }
  handleChangeUserAccessTokenSecret(e){
    this.setState({ userAccessTokenSecret: e.target.value });
  }


  handleSubmit(e){
    const today = new Date();
    const date = today.getFullYear() + '/' + (today.getMonth() + 1) + '/' + today.getDate() + '     ' + today.getHours() + ':' + today.getMinutes() + ':' + today.getSeconds() + ' ';
    if (this.state.id == '' || this.state.watchid == 'GarminWatch' || this.state.account == '' || this.state.password == '' || this.state.userAccessToken == '' || this.state.userAccessTokenSecret == '') {
      alert('有資料未填寫！');
      e.preventDefault(); 
    }
    else {
      ref.collection('Watch').doc().set({
        "id": this.state.id,
        "watchid": this.state.watchid,   // 手錶編號
        "account": this.state.account,    // 輪班別
        "password": this.state.password,   // 工作時數
        "userAccessToken": this.state.userAccessToken,   // 加班天數
        "userAccessTokenSecret": this.state.userAccessTokenSecret,    // 請假天數
        "firebaseid": "",
        "createdTime": date
      });
      console.log('新增成功');
      ref.collection('MaxWatchNum').doc('MaxWatchId').set({
        "maxid": Number(this.state.id)
      });
    }
  }

  componentDidMount(){
      this.getMaxWatchNum()
  }

  render() {
    const h2={
      fontSize: '2rem',
      fontWeight: 'bold',
      paddingTop: '10px'
    }
    const h4={
      fontSize: '1.5rem',
      fontWeight: 'bold',
    }
    const p={
      fontSize: '1.5rem',
      color: '#50a9b9'
    }
    const line={
      height: '1px',
      borderTop: 'solid gray 1px',
      margin: '10px 0'
    }
    const margin={
      margin: '10px',
    }
    const button={
      margin: '5px',
      float:'right'
    }
    return(
      <div>
        <Headers/>
        <Container>
          <h2 style={h2}>新增手錶資料</h2>
          <Container style={line}></Container>
          <p style={h4}>目前手錶數量  ：  {this.state.maxid}</p>
          <Form style={margin}>
            <Row className="g-2">
              <Col md>
                <p style={h4}>編號：</p>
                <Form.Control
                  placeholder="ID"
                  value={this.state.id}
                  onChange={this.handleChangeID.bind(this)}
                />
                <p style={h4}>手錶編號：</p>
                <Form.Control
                  placeholder="手錶編號"
                  value={this.state.watchid}
                  onChange={this.handleChangeWatchId.bind(this)}
                />
              </Col>
              <Col md>
                <p style={h4}>Account：</p>
                <Form.Control
                  placeholder="Account"
                  value={this.state.account} 
                  onChange={this.handleChangeAccount.bind(this)}
                />
                <p style={h4}>Password：</p>
                <Form.Control
                  placeholder="Password"
                  value={this.state.password} 
                  onChange={this.handleChangePassword.bind(this)}
                />
                <p style={h4}>UserAccessToken：</p>
                <Form.Control
                  placeholder="UserAccessToken："
                  value={this.state.userAccessToken} 
                  onChange={this.handleChangeUserAccessToken.bind(this)}
                />
                <p style={h4}>UserAccessTokenSecret：</p>
                <Form.Control
                  placeholder="UserAccessTokenSecret"
                  value={this.state.userAccessTokenSecret}  
                  onChange={this.handleChangeUserAccessTokenSecret.bind(this)}
                />
              </Col>
            </Row>
            {/**顯示使用者輸入、選項 */}
            <Form style={h4}>
              <h2 style={h2}>請確認以下資料：</h2>
              <Container style={line}></Container>
              <Row>
                <Col md>
                  編號  ： <span style={p}>{this.state.id}</span><br/>
                手錶編號  ： <span style={p}>{this.state.watchid}</span><br/>
                </Col>
                <Col md>
                  帳號  ： <span style={p}>{this.state.account}</span><br/>
                  密碼  ： <span style={p}>{this.state.password}</span><br/>
                  UAT  ： <span style={p}>{this.state.userAccessToken}</span><br/>
                  UAT Secret  ： <span style={p}>{this.state.userAccessTokenSecret}</span><br/>
                </Col>
              </Row>
            </Form><br/>
            <Link to="/ner/watch"><Button onClick={this.handleSubmit} variant="outline-success" style={button} size="lg">新增</Button></Link>
          </Form>
        </Container>
      </div>
    );
  };
  
};
export default AddWatch;
