import {Menu} from 'semantic-ui-react';
import {Link} from 'react-router-dom';
import React from 'react';
import Topics from "./components/Topics_NAR";

function Headers(){
    const ul={
        listStyleType: 'none',
        margin: '0',
        padding: '0',
        overflow: 'hidden',
        backgroundColor: 'lightblue',
    }
    const li={
        float: 'left',
        display: 'block',
        color: '#333',
        textAlign: 'center',
        padding: '14px 16px',
        textDecoration: 'none',
        fontSize: '25px',
        'font-family': "Helvetica, Arial, 'LiHei Pro', '黑體-繁', '微軟正黑體', sans-serif"
    }
    const a={
        float: 'right',
        display: 'block',
        color: '#333',
        textAlign: 'center',
        padding: '14px 16px',
        textDecoration: 'none',
        fontSize: '25px',
        'font-family': "Helvetica, Arial, 'LiHei Pro', '黑體-繁', '微軟正黑體', sans-serif"
    }
    return(
        <Menu style={ul}>
            <Menu.Item style={li}>代理伺服器_安寧病房</Menu.Item>
            <Menu.Item as={Link} to="/nar" style={a}>登出</Menu.Item>
            <Topics style={li}/>
        </Menu>
    );
}
export default Headers;

