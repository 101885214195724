import firebase_AR from 'firebase/compat/app';

const firebaseConfig_AR = {
  apiKey: "AIzaSyCTt9K-SvRbMn5g1QN_Uhy7nhgYZcLOKg4",
  authDomain: "firevbase-webrr.firebaseapp.com",
  databaseURL: "https://firevbase-webrr-default-rtdb.firebaseio.com",
  projectId: "firevbase-webrr",
  storageBucket: "firevbase-webrr.appspot.com",
  messagingSenderId: "802050092714",
  appId: "1:802050092714:web:eb3991b105f20036442ad3",
  measurementId: "G-G1L64FX1X0"
};

const FirebaseAR = firebase_AR.initializeApp(firebaseConfig_AR,'another');


export default FirebaseAR;
