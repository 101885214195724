import { useState,useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import Headers from '../Headers_NAR.js';
// bootstrapt
import { Table, Container, Row, Col, Button } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
// 連接 Firebase
import firebase from '../config/database_AR.js';
import 'firebase/compat/firestore';
// ref 路徑連到 Month 資料庫
const ref = firebase.firestore().collection('Month');

const MonthList = () => {
    const [months, setMonths] = useState([]);
    useEffect(()=>{
        getMonths();
    }, []);
    
    // 刪除 Month 資料
    const deleteMonth = async (firebaseid) => {
        ref.doc(firebaseid).delete().then(() => {
            alert('刪除成功！');
        });
        window.setTimeout(( () => window.location.reload() ), 1250);
    }

    // 取得 Month 所有的資料
    const getMonths = async () =>{
        const monthData = [];
        const monthId = [];
        // 使用 get() 的方式，一次性讀取資料
        ref.orderBy('watchid').get().then(items => {
            items.forEach(doc => {
                monthId.push(doc.id);
                monthData.push(doc.data());
            });
            for (let i = 0; i < monthData.length; i++){
                ref.doc(monthId[i]).update({
                    "firebaseid": monthId[i]
                });
                monthData[i].firebaseid = monthId[i];
            }
            setMonths(monthData);
        });
    }
    
    
    const h2={
        fontSize: '2rem',
        fontWeight: 'bold',
        paddingTop: '10px'
    }
    const h3={
        fontSize: '2rem',
        fontWeight: 'bold',
        paddingTop: '50px',
        marginBottom: '-10px'
    }
    const line={
        height: '1px',
        borderTop: 'solid gray 1px',
        margin: '10px 0'
    }
    const textCenter ={
        textAlign: 'center',
    }
    return (
        <div>
            <Headers/>
                <Container fluid="md">
                    <Row>
                        <Col>
                            <h2 style={h2}>安寧病房 出缺勤資料</h2>
                            <Container style={line}></Container>
                            <Link to="/nar/addmonth"><Button variant="outline-success" size="lg">新增</Button></Link>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                        <h3 style={h3}>資料顯示</h3>
                        <br />
                        <Table striped bordered hover style={textCenter}>
                                <thead>
                                    <tr>
                                        <th>No.</th>
                                        <th>手錶編號</th>
                                        <th>輪班班別</th>
                                        <th>工作時數</th>
                                        <th>加班時數</th>
                                        <th>請假天數</th>
                                        <th>建檔日期</th>
                                        <th>修改日期</th>
                                        <th>修改刪除</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {months.map((Month,index)=> {
                                        return(
                                            <tr key={Month.firebaseid}>
                                                <td>{index + 1}</td>
                                                <td>{Month.watchid}</td>
                                                <td>{Month.shift}</td>
                                                <td>{Month.workhour}</td>
                                                <td>{Month.overhour}</td>
                                                <td>{Month.leave}</td>
                                                <td>{Month.createdTime}</td>
                                                <td>{Month.editedTime}</td>
                                                <td>
                                                    <Link to={'/nar/editmonth/'+Month.firebaseid}><Button variant="warning">修改</Button></Link> {' '}
                                                    <Button onClick={() => deleteMonth(Month.firebaseid)} variant="outline-danger">刪除</Button>
                                                </td>
                                            </tr>
                                        );
                                    })}   
                                </tbody>
                            </Table>
                        </Col>
                    </Row>
                    
                </Container>
        </div>
    );
}
export default MonthList;
