// 數據分析 - 心率指數
import React, { PureComponent } from 'react';
import Select from 'react-select';
import { Link } from 'react-router-dom';
import Headers from '../Headers_NAR.js';
import axios from "axios";
// bootstrap
// bootstrap
import { Table, Container, Row, Col } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
// 數線圖
import { AreaChart, Area, BarChart, Bar, Cell, Legend, LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, LabelList, } from 'recharts';
// 連接 Firebase
import firebase from '../config/database_AR.js';
import 'firebase/compat/firestore';
const ref = firebase.firestore();

export default class HeartRateAnalyze_AR extends PureComponent {
  constructor(props){
    super(props)
    this.state = {
      selectWatchOptions : [],
      watch_UAT: '',
      watch_id: '',
      HeartRateData:[],  //此陣列存放完整的心率資料
      selectCalendarOptions: [], //此陣列存放日期陣列
      calendar_id: '',
      calendar_Date: '',
    }
  }

  //! 取得 watch 資料
  async getWatchOptions(){
    const watchData = [];
    const watchId = [];
    // 使用 get() 的方式，一次性讀取資料
    ref.collection('Watch').orderBy('account').get().then(items => {
      items.forEach(doc => {
        watchId.push(doc.id);
        watchData.push(doc.data());
      });
      for (let i = 0; i < watchData.length; i++){
        watchData[i].firebaseid = watchId[i];
        const watchoptions = watchData.map(Watch => ({
          "value": Watch.userAccessToken,
          "label": Watch.watchid
        }));
        this.setState({selectWatchOptions: watchoptions});
      }
    });
  }

  // 取得日期
  async getCalendarOptions(uat) {
      console.log(uat);
      const response = await axios.get('https://imow.nutc.edu.tw:8091/heartratecalendar/'+ uat);   // 到 heartratecalendar 地址抓取 日期 陣列
      // const response = await axios.get('https://localhost:8080/heartratecalendar/'+ uat);   // 到 heartratecalendar 地址抓取 日期 陣列
      const calendarArray = response.data;  // calendarArray 存放傳回來的 日期陣列
      // 將 日期陣列 中的資料放到 calendaroptions 中
      const calendaroptions = calendarArray.map(Calendar => ({
        "value" : Calendar.id,
        "label": Calendar.calendar,
      }));
      console.log('+++' + calendaroptions);
      this.setState({selectCalendarOptions: calendaroptions});
  }
  
  // 取得 心率 資料
  async getHeartRateData(uat, watchid, calendar) {
    console.log(uat, watchid, calendar);
    // 到後端抓取 心率 的資料
    const response = await axios.post('https://imow.nutc.edu.tw:8091/heartrateanalyze', { uat, watchid, calendar });
    // const response = await axios.post('https://localhost:8080/heartrateanalyze', { uat, watchid, calendar });
    // 如果沒有資料
    if (response.data == '') {
      this.setState({ HeartRateData: response.data }); //將內部陣列存入外層陣列
      console.log(response.data);
      // 跳出通知
      alert('無資料');
    } else {
      console.log(response.data);
      this.setState({HeartRateData: response.data}); //將內部陣列存入外層陣列
    }
  }


  handleChangeWatch(e){
    this.setState({ watch_UAT: e.value, watch_id: e.label });
    this.getCalendarOptions(e.value);
    
  }

  handleChangeCalendar(e) {
    this.setState({ calendar_id: e.value, calendar_Date: e.label });
    this.getHeartRateData(this.state.watch_UAT,this.state.watch_id, e.label);
  }

  componentDidMount(){
    this.getWatchOptions();
  }

  render() {
    const h2={
        fontSize: '2rem',
        fontWeight: 'bold',
        paddingTop: '10px'
    }
    const h4={
        fontSize: '2rem',
        fontWeight: 'bold',
      marginTop: '30px',
        marginBottom: '20px'
    }
    const h3={
        fontSize: '1.5rem',
      fontWeight: 'bold',
        marginTop: '20px',
        marginBottom: '10px'
    }
    const line={
      height: '1px',
      borderTop: 'solid gray 1px',
      margin: '10px 0'
    }
    const textCenter={
      textAlign: 'center',
    }
    return (
      <div>
        <Container>
          <Row>
            <Col md>
              <h3 style={h3}>手錶編號：</h3>
              <Select options={this.state.selectWatchOptions} onChange={this.handleChangeWatch.bind(this)} /><br />
            </Col>
            <Col md>
              <h3 style={h3}>選擇日期：</h3>
              <Select options={this.state.selectCalendarOptions} onChange={this.handleChangeCalendar.bind(this)} /><br />
            </Col>
          </Row>
          <h2 style={h2}>{this.state.calendar_Date} 心率指數（秒／bmp）</h2>
          {/* 數線圖 */}
          <Container style={line}></Container>
          <ResponsiveContainer width='100%' height={200}>
            <AreaChart
              width={500}
              height={200}
              data={this.state.HeartRateData}
              margin={{
                top: 10,
                right: 5,
                left: 0,
                bottom: 0,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="Time" />
              <YAxis />
              <Tooltip />
              <Area connectNulls type="monotone" dataKey="HeartRate" stroke="#8884d8" fill="#8884d8" />
            </AreaChart>
          </ResponsiveContainer>
          <br />
          <div style={textCenter}>
            <span style={{ color: '#66a182' }}> &lt; 60：心跳過慢</span>&emsp;
            <span style={{ color: 'black' }}> 60 ~ 100：心跳正常</span>&emsp;
            <span style={{ color: 'red' }}> &gt; 100：心跳過快</span>&emsp;
          </div>
          {/* 表格 */}
          <h4 style={h4}>資料顯示</h4>
          <Table striped bordered hover style={textCenter}>
            <thead>
              <tr>
                <th>NO</th>
                <th>User</th>
                <th>Calendar</th>
                <th>Time</th>
                <th>Heart Rate</th>
                <th>Heart Rate Level</th>
              </tr>
            </thead>
            <tbody>
              {this.state.HeartRateData.map((HeartRate, index) => {
                if (HeartRate.HeartRateLevel == '過快') {
                  return(
                    <tr>
                      <td>{index + 1}</td>
                      <td>{HeartRate.Watch_id}</td>
                      <td>{HeartRate.Calendar}</td>
                      <td>{HeartRate.Time}</td>
                      <td>{HeartRate.HeartRate}</td>
                      <td style={{ color: 'red' }}>{HeartRate.HeartRateLevel}</td>
                    </tr>
                  );
                }
                else if (HeartRate.HeartRateLevel == '過慢') {
                  return(
                    <tr>
                      <td>{index + 1}</td>
                      <td>{HeartRate.Watch_id}</td>
                      <td>{HeartRate.Calendar}</td>
                      <td>{HeartRate.Time}</td>
                      <td>{HeartRate.HeartRate}</td>
                      <td style={{ color: '#66a182' }}>{HeartRate.HeartRateLevel}</td>
                    </tr>
                  );
                }
                else if (HeartRate.HeartRateLevel == '正常') {
                  return(
                    <tr>
                      <td>{index + 1}</td>
                      <td>{HeartRate.Watch_id}</td>
                      <td>{HeartRate.Calendar}</td>
                      <td>{HeartRate.Time}</td>
                      <td>{HeartRate.HeartRate}</td>
                      <td style={{ color: 'black' }}>{HeartRate.HeartRateLevel}</td>
                    </tr>
                  );
                }
              })}   
            </tbody>
          </Table>
        </Container>
      </div>
    );
  }
}
