import { useState,useEffect } from "react";
import { Link } from "react-router-dom";
// bootstrapt
import { Table, Container, Row, Col, Button } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
// 連接 Firebase
import firebase from '../config/database_AR.js';
import 'firebase/compat/firestore';
import Headers from '../Headers_NAR.js';
// 取得 Firebase 中的 Personal 資料表
const ref = firebase.firestore().collection('Watch');
const WatchList = () => {
    const [watch, setWatch] = useState([]);
    useEffect(()=>{
        getWatch();
    }, []);
    
    const deleteWatch = async (firebaseid) => {
        ref.doc(firebaseid).delete().then(() => {
            console.log('delete ',firebaseid,' successful');
        });
        window.setTimeout(( () => window.location.reload() ), 1300);
    }
    const getWatch = async () =>{
        // const response = await axios.get('http://localhost:5000/watch');
        // const resData = response.data;    // 包含了 firebaseid 與 "資料" 內容
        // setWatch(resData);
        const watchData = [];
        const watchId = [];
        // 使用 get() 的方式，一次性讀取資料
        ref.orderBy('watchid').get().then(items => {
        items.forEach(doc => {
            watchId.push(doc.id);
            watchData.push(doc.data());
        });
        for (let i = 0; i < watchData.length; i++){
            watchData[i].firebaseid = watchId[i];
            // console.log(watchData[i]);
        }
        setWatch(watchData);
        });
    }

    const h2={
        fontSize: '2rem',
        fontWeight: 'bold',
        paddingTop: '10px'
    }
    const h3={
        fontSize: '2rem',
        fontWeight: 'bold',
        paddingTop: '50px',
        marginBottom: '-10px'
    }
    const line={
        height: '1px',
        borderTop: 'solid gray 1px',
        margin: '10px 0'
    }
    const button={
        margin: '5px',
    }
    const textCenter={
        textAlign: 'center',
    }
    return (
        <div>
            <Headers/>
            <Container>
                <Row>
                    <Col>
                        <h2 style={h2}>Garmin Watch 手錶</h2>
                        <Container style={line}></Container>
                        <Link to="/nar/addwatch"><Button variant="outline-success" size="lg">新增</Button></Link>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <h3 style={h3}>資料顯示</h3>
                        <br />
                        <Table striped bordered hover style={textCenter}>
                            <thead>
                                <tr>
                                    <th>No.</th>
                                    <th>手錶編號</th>
                                    <th>帳號</th>
                                    <th>密碼</th>
                                    <th>userAccessToken</th>
                                    <th>userAccessTokenSecret</th>
                                    <th>建檔日期</th>
                                    <th>修改日期</th>
                                    <th>修改刪除</th>
                                </tr>
                            </thead>
                            <tbody>
                                {watch.map((Watch,index)=> {
                                    return(
                                        <tr key={Watch.firebaseid}>
                                            <td>{Watch.id}</td>
                                            <td>{Watch.watchid}</td>
                                            <td>{Watch.account}</td>
                                            <td>{Watch.password}</td>
                                            <td>{Watch.userAccessToken}</td>
                                            <td>{Watch.userAccessTokenSecret}</td>
                                            <td>{Watch.createdTime}</td>
                                            <td>{Watch.editedTime}</td>
                                            <td>
                                                <Link to={'/nar/editwatch/'+Watch.firebaseid}><Button variant="warning" style={button}>修改</Button></Link>
                                                <Button onClick={() => deleteWatch(Watch.firebaseid)} variant="outline-danger" style={button}>刪除</Button>
                                            </td>
                                        </tr>
                                    );
                                })}   
                            </tbody>
                        </Table>
                    </Col>
                </Row>
                    
                </Container>
        </div>
    );
}
export default WatchList;
