import React, { Component } from 'react';
import Select from 'react-select';
import { Link } from 'react-router-dom';
import Headers from '../Headers_NAR.js';
// bootstrapt
import { Form, Container, Row, Col, Button } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
// 連接 Firebase
import firebase from '../config/database_AR.js';
import 'firebase/compat/firestore';
const ref = firebase.firestore();

//新增月更資料頁
class AddMonth extends Component {

  constructor(props){
    super(props)
    this.state = {
      Account: "",             // 帳號
      selectShiftOptions : [], //輪班別
      shift_id: "",            //輪班_id
      shift_name: '',          //輪班_選項
      selectWatchOptions : [], //手錶使用者
      watch_id: '',            //手錶使用者_id
      watch_watchid: '',       //手錶使用者_選項
      //以下為使用者輸入資料
      watchid:'',             //手錶使用者
      shift:'',               //輪班
      workhour:'',            //工作時數
      overhour:'',            //加班時數
      leave: '',               //請假天數
      uat:'',                  // userAccessToken
    }
  }

  // 取得手錶使用者
  async getWatchOptions(){
    const watchData = [];
    const watchId = [];
    // 使用 get() 的方式，一次性讀取資料
    ref.collection('Watch').orderBy('account').get().then(items => {
      items.forEach(doc => {
        watchId.push(doc.id);
        watchData.push(doc.data());
      });
      //將從資料庫得到的資料存進watchoption下拉選單
      for (let i = 0; i < watchData.length; i++){
        watchData[i].firebaseid = watchId[i];
        const watchoptions = watchData.map(Watch => ({
          "value": Watch.id,
          "label": Watch.watchid
        }));
        //需將selectWatchOptions資料為watchoptions，外層才可以使用
        this.setState({selectWatchOptions: watchoptions});
        this.handleSubmit = this.handleSubmit.bind(this);
        // console.log(watchData[i]);
      }
    });
  }

  // 取得 輪班別 資料
  async getShiftOptions(){
    const data = [];
    ref.collection('ShiftMenu').orderBy('id').onSnapshot(querySnapshot => {
      querySnapshot.forEach(doc => {
        data.push(doc.data());
      });
      const shiftoptions = data.map(Shiftmenu => ({
      "value" : Shiftmenu.id,
      "label" : Shiftmenu.name
    }))
    // console.log(shiftoptions);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.setState({selectShiftOptions: shiftoptions})
    });
  }


  // handleChange...(e) 用於儲存變動的變數資料
  handleChangeWatch(e){
    this.setState({ watch_id: e.value, watch_watchid: e.label });
    const watchData = [];
    // 取得 firebase 中的 Watch 資料，並存入 watchData 中
    ref.collection('Watch').get().then(items => {
      items.forEach(doc => {
        watchData.push(doc.data());
      });
      for (let i = 0; i < watchData.length; i++){
        // 找到目前選擇手錶的資料
        if (e.label == watchData[i].watchid) {
          this.setState({ uat: watchData[i].userAccessToken });
          this.setState({ Account: watchData[i].account });
        }
      }
    });
  }
  handleChangeShift(e){
    this.setState({ shift_id: e.value, shift_name: e.label });
  }
  handleChangeWorkhovr(e){
    this.setState({ workhour: e.target.value });
  }
  handleChangeOverhovr(e){
    this.setState({ overhour: e.target.value });
  }
  handleChangeLeave(e){
    this.setState({ leave: e.target.value });
  }
  //將新增資料到資料庫
  handleSubmit(e) {
    const today = new Date();
    const date = today.getFullYear() + '/' + (today.getMonth() + 1) + '/' + today.getDate() + '     ' + today.getHours() + ':' + today.getMinutes() + ':' + today.getSeconds() + ' ';
    
    if (this.state.watch_watchid == '' || this.state.shift_name == '' || this.state.workhour == '' || this.state.overhour == '' || this.state.leave == '') {
      alert('有資料未填寫！');
      e.preventDefault(); 
    }
    else {
      ref.collection('Month').doc().set({
        "account": this.state.Account,     // 帳號
        "watchid": this.state.watch_watchid,   // 手錶編號
        "shift":this.state.shift_name,    // 輪班別
        "workhour":this.state.workhour,   // 工作時數
        "overhour":this.state.overhour,   // 加班天數
        "leave": this.state.leave,    // 請假天數
        "firebaseid": "",               // firebase id
        "uat":this.state.uat,         // userAccessToken
        "createdTime": date,        // 建檔日期
      });
      console.log('新增成功');
    }
  }
  //進入頁面自動執行
  componentDidMount(){
      this.getWatchOptions()
      this.getShiftOptions()
  }

  render() {
    const h2={
      fontSize: '2rem',
      fontWeight: 'bold',
      paddingTop: '10px'
    }
    const h4={
      fontSize: '1.5rem',
      fontWeight: 'bold',
    }
    const p={
      fontSize: '1.5rem',
      color: '#50a9b9'
    }
    const line={
      height: '1px',
      borderTop: 'solid gray 1px',
      margin: '10px 0'
    }
    const margin={
      margin: '10px',
    }
    const button={
      margin: '5px',
      float:'right'
    }
    return(
      <div>
        <Headers/>
        <Container>
          <h2 style={h2}>新增出缺勤資料</h2>
          <Container style={line}></Container>
          <Form style={margin}>
            <Row className="g-2">
              {/**下拉選單選項 */}
              <Col md>
                <p style={h4}>手錶編號：</p>
                <Select options={this.state.selectWatchOptions} onChange={this.handleChangeWatch.bind(this)} />
                <p style={h4}>輪班班別：</p>
                <Select options={this.state.selectShiftOptions} onChange={this.handleChangeShift.bind(this)} />
              </Col>
              {/**使用者輸入選項 */}
              <Col md>
                <p style={h4}>工作時數：</p>
                <Form.Control
                  type="number"
                  placeholder="請輸入工作時數"
                  value={this.state.workhour}
                  onChange={this.handleChangeWorkhovr.bind(this)}
                />
                <p style={h4}>加班天數：</p>
                <Form.Control
                  type="number"
                  placeholder="請輸入加班"
                  value={this.state.overhour} 
                  onChange={this.handleChangeOverhovr.bind(this)}
                />
                <p style={h4}>請假天數：</p>
                <Form.Control
                  type="number"
                  placeholder="請輸入請假天數"
                  value={this.state.leave} 
                  onChange={this.handleChangeLeave.bind(this)}
                />
              </Col>
            </Row>
            <br />
            <br />
            {/**顯示使用者輸入、選項 */}
            <Form style={h4}>
              <h2 style={h2}>請確認以下資料：</h2>
              <Container style={line}></Container>
              <Row>
                <Col md>
                  手錶編號 ： <span style={p}>{this.state.watch_watchid}</span><br/>
                  輪班班別 ： <span style={p}>{this.state.shift_name}</span><br/>
                </Col>
                <Col md>
                  工作時數 ： <span style={p}>{this.state.workhour}</span><br/>
                  加班時數 ： <span style={p}>{this.state.overhour}</span><br/>
                  請假天數 ： <span style={p}>{this.state.leave}</span><br/>
                </Col>
              </Row>
            </Form><br/>
            <Link to="/nar/month"><Button onClick={this.handleSubmit} variant="outline-success" style={button} size="lg">新增</Button></Link>
          </Form>
        </Container>
      </div>
    );
  }
  
}
export default AddMonth; //將AddMonth 渲染出去
