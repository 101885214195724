import React, { Component } from 'react';
import Select from 'react-select';
import axios from 'axios';
import { Link } from 'react-router-dom';
import Headers from '../Headers_NAR.js';
// bootstrapt
import { Form, Container, Row, Col, Button } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
// 連接 Firebase
import firebase from '../config/database_AR.js';
import 'firebase/compat/firestore';
const ref = firebase.firestore();

// 修改 月更新資料
class EditMonth extends Component {

  constructor(props){
    super(props)
    this.state = {
      monthid:"",
      selectShiftOptions : [],
      shift_id: "",
      shift_name: '',
      selectWatchOptions : [],
      watch_id: '',
      watch_watchid: '',
      workhour:'',
      overhour:'',
      leave:'',
    }
  }

  // 取得 其中一筆 月更新資料
  async getMonthById(firebaseid) {
    ref.collection('Month').doc(firebaseid).get().then(items => {
    const data = items.data();    // 所有資料

    const watchidvalue = data.watchid;  // 手錶資料
    const shiftvalue = data.shift;      // 輪班別
    const workhourvalue = data.workhour;    // 工作時數
    const overhourvalue = data.overhour;    // 加班天數
    const leavevalue = data.leave;          // 請假天數
    this.setState({monthid:firebaseid,watch_watchid:watchidvalue,shift_name:shiftvalue,workhour: workhourvalue ,overhour: overhourvalue,leave:leavevalue})
    })
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  // 取得 watch 資料
  async getWatchOptions(){
    const watchData = [];
    const watchId = [];
    // 使用 get() 的方式，一次性讀取資料
    ref.collection('Watch').orderBy('account').get().then(items => {
      items.forEach(doc => {
        watchId.push(doc.id);
        watchData.push(doc.data());
      });
      for (let i = 0; i < watchData.length; i++){
        const watchoptions = watchData.map(Watch => ({
          "value": Watch.id,
          "label": Watch.watchid
        }));
        this.setState({selectWatchOptions: watchoptions});
        this.handleSubmit = this.handleSubmit.bind(this);
        // console.log(watchData[i]);
      }
    });
  }

  // 取得 輪班別資料
  async getShiftOptions(){
    const data = [];
    ref.collection('ShiftMenu').orderBy('id').onSnapshot(querySnapshot => {
      querySnapshot.forEach(doc => {
        data.push(doc.data());
      });
      const shiftoptions = data.map(Shiftmenu => ({
      "value" : Shiftmenu.id,
      "label" : Shiftmenu.name
    }))
    // console.log(shiftoptions);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.setState({selectShiftOptions: shiftoptions})
    });
  }
  
  handleChangeWatch(e){
    this.setState({watch_id:e.value, watch_watchid:e.label})
  }
  handleChangeShift(e){
    this.setState({shift_id:e.value, shift_name:e.label})
  }
  handleChangeWorkhovr(e){
    this.setState({workhour:e.target.value})
  }
  handleChangeOverhovr(e){
    this.setState({overhour:e.target.value})
  }
  handleChangeLeave(e){
    this.setState({leave:e.target.value})
  }
  handleSubmit(e) {
    const today = new Date();
    const date = today.getFullYear()+'/'+(today.getMonth()+1)+'/'+today.getDate()+'     '+today.getHours()+':'+today.getMinutes()+':'+today.getSeconds()+' ';
    ref.collection('Month').doc(this.state.monthid).update({
      "shift":this.state.shift_name,
      "workhour":this.state.workhour,
      "overhour":this.state.overhour,
      "leave":this.state.leave,
      "editedTime": date
    });
    console.log('修改成功');
  }

  componentDidMount(){
    this.getWatchOptions()
    this.getShiftOptions()
    this.getMonthById(this.props.match.params.firebaseid)
  }

  render() {
    const h2={
      fontSize: '2rem',
      fontWeight: 'bold',
      paddingTop: '10px'
    }
    const h4={
      fontSize: '1.5rem',
      fontWeight: 'bold',
    }
    const p={
      fontSize: '1.5rem',
      color: '#50a9b9'
    }
    const line={
      height: '1px',
      borderTop: 'solid gray 1px',
      margin: '10px 0'
    }
    const margin={
      margin: '10px',
    }
    const button={
      margin: '5px',
      float:'right'
    }
    return(
      <div>
        <Headers/>
        <Container>
          <h2 style={h2}>修改出缺勤紀錄</h2>
          <Container style={line}></Container>
          <Form style={margin}>
            <p style={h4}>手錶編號：<span style={p}>{this.state.watch_watchid}</span></p>
            <Row className="g-2">
              <Col md>
                <p style={h4}>輪班班別：</p>
                <Select options={this.state.selectShiftOptions} onChange={this.handleChangeShift.bind(this)} />
                <p style={h4}>工作時數：</p>
                <Form.Control
                  type="number"
                  placeholder="請輸入工作時數"
                  onChange={this.handleChangeWorkhovr.bind(this)}
                />
              </Col>
              <Col md>
                <p style={h4}>加班天數：</p>
                <Form.Control
                  type="number"
                  placeholder="請輸入加班"
                  onChange={this.handleChangeOverhovr.bind(this)}
                />
                <p style={h4}>請假天數：</p>
                <Form.Control
                  type="number"
                  placeholder="請輸入請假天數"
                  onChange={this.handleChangeLeave.bind(this)}
                />
              </Col>
            </Row>
            <br/>
            <br />
            <Form style={h4}>
              <h2 style={h2}>請確認以下資料：</h2>
              <Container style={line}></Container>
              手錶編號  ： <span style={p}>{this.state.watch_watchid}</span><br/>
              <Row>
                <Col>
                  輪班班別  ： <span style={p}>{this.state.shift_name}</span><br/>
                  工作時數  ： <span style={p}>{this.state.workhour}</span><br/>
                </Col>
                <Col> 
                  加班時數  ： <span style={p}>{this.state.overhour}</span><br/>
                  請假天數  ： <span style={p}>{this.state.leave}</span><br/>
                </Col>
              </Row>
            </Form><br/>
            <Link to="/nar/month"><Button onClick={this.handleSubmit} variant="outline-success" style={button} size="lg">確認修改</Button></Link>
          </Form>
        </Container>
      </div>
    );
  }
  
}
export default EditMonth;
